import { AppConstants } from "../../../constants/AppConstants";
import BaseAPIClient from "../../../http/api";

const globalHeaders: any = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
};

const API_BASE_URL: string =
  process.env.REACT_APP_API_URL || "http://localhost";
const API = BaseAPIClient?.axiosInstance;

export const checkifUserLoggedIn = async () => {
  const headers = { ...globalHeaders };
  let uri: string = `${API_BASE_URL}${AppConstants.HUBSPOT_CHECK_USER_LOGGED_IN}`;
  const response = await API.get(uri, {
    headers: { ...headers },
  });
  console.log('.....<><><><>',response);
  return response.data;
};

export const authenticateAndFetchAccessToken = async (code : any) => {
  const headers = { ...globalHeaders };
  let uri: string = `${API_BASE_URL}${AppConstants.HUBSPOT_ACCESS_TOKEN_FETCH}${code}`;
  const response = await API.put(uri, {
    headers: { ...headers },
  });
  return response.data;
}

export const fetchAllListsMetadataForUser = async (payload: any) => {
  const headers = { ...globalHeaders };
  let uri: string = `${API_BASE_URL}${AppConstants.HUBSPOT_FETCH_USER_LISTS_METADATA}`;
  const response = await API.post(uri,payload, {
    headers: { ...headers },
  });
  return response.data;
}

export const submitHubspotListForBatchExecution = async (payload: any) => {
  const headers = { ...globalHeaders };
  const uri: string = `${API_BASE_URL}${AppConstants.HUBSPOT_FILE_SUBMIT_FOR_BATCH}`;
  const response = await API.post(uri, payload, {
    headers: { ...headers },
  });
  return response;
};

export const logoutHubspotUser = async () => {
  const headers = { ...globalHeaders };
  const uri: string = `${API_BASE_URL}${AppConstants.HUBSPOT_USER_LOGOUT}`;
  const response = await API.put(uri, {
    headers: { ...headers },
  });
  return response;
};