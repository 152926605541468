import Grid from "@mui/material/Grid2";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ForwardArrow } from "../../assets/svgs";
import { AppConstants } from "../../constants/AppConstants";
import { ConstantTexts } from "../../constants/ConstantTexts";
import { RouteConstants } from "../../constants/RouteConstants";
import { EncryptPasswordAES } from "../../utils/passwordEncryption";
import {
  formatName,
  formatPhone,
  validatePassword,
  validatePhone,
  validationEmail,
} from "../../utils/validations";
import DropDown from "./DropDown";
import InputTextField, { InputErrortext } from "./InputTextField";
import { CheckBoxComponent } from "./ToggleComponents";
import TrestleButton from "./TrestleButton";
import Alerttriangle from "../../assets/Alert-Triangle.svg";
import Cookies from "js-cookie";

interface SignUpFormProps {
  type: "SIGNUP" | "ADD_NEW_USER" | "ADD_NEW_TEAM_MEMBER" | "";
  SignUpFinalCall: any;
  setIsLoading?: any;
  setTermsDialog?: any;
  setPopup?: any;
  selectedUsers?: any;
  teamData?: any;
}

const Optionaloptions = [
  "Search Engines (Google, Bing, etc.)",
  "Internet Ads",
  "LinkedIn",
  "Trestle Blog",
  "Word of Mouth",
  "Referral",
  "Third-Party Review Site (G2, CB Insights)",
  "Other",
];

const SingUpUserForm = ({
  type,
  SignUpFinalCall,
  setIsLoading,
  setTermsDialog,
  setPopup,
  selectedUsers,
  teamData,
}: SignUpFormProps) => {
  const navigate = useNavigate();

  const gclId = Cookies.get("_gcl_aw")?.split(".")[2];
  const firstCampaign = Cookies.get("first_campaign");
  const firstSourceMedium = Cookies.get("first_source_medium");
  const firstReferral = Cookies.get("first_referral");
  const recentCampaign = Cookies.get("recent_campaign");
  const recentSourceMedium = Cookies.get("recent_source_medium");
  const recentTerm = Cookies.get("recent_term");
  const firstTerm = Cookies.get("first_term");

  const [formData, setFormData] = useState<any>({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    // optional: type === "SIGNUP" ? "" : "Invited By Admin",
    other: "",
    password: "",
    terms: false,
    company: teamData ? teamData.company : "",
    gclid: gclId ? gclId : "",
    firstCampaign: firstCampaign ? firstCampaign : "",
    firstSourceMedium: firstSourceMedium ? firstSourceMedium : "",
    firstReferral: firstReferral ? firstReferral : "",
    recentCampaign: recentCampaign ? recentCampaign : "",
    recentSourceMedium: recentSourceMedium ? recentSourceMedium : "",
    recentTerm: recentTerm ? recentTerm : "",
    firstTerm: firstTerm ? firstTerm : "",
  });
  const [formError, setFormError] = useState<any>({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    // optional: "",
    password: "",
    terms: "",
    other: "",
    company: "",
  });

  const OnDataChange = (event: any) => {
    const { value, name } = event.target;
    let val: any = value;
    if (val.trim()) {
      if (name === "phone") {
        let t = formatPhone(val);
        val = t || formData.phone;
      }
      if (name === "firstname" || name === "lastname" || name === "company") {
        val = formatName(val);
      }
    }
    setFormData({
      ...formData,
      [name]: val,
    });
  };

  const disabledSubmit = () => {
    const {
      firstname = "",
      lastname = "",
      email = "",
      phone = "",
      password = "",
      terms = false,
      // optional = "",
      other = "",
    } = formData || {};

    return !(
      firstname.trim() &&
      lastname.trim() &&
      email.trim() &&
      (type === "SIGNUP" ? phone.trim() : true) &&
      (type === "SIGNUP" ? password.trim() : true) &&
      (type === "SIGNUP" ? terms : true)
    );
  };

  const getSignUpPayload = () => {
    const {
      email,
      firstname,
      lastname,
      phone,
      // optional,
      password,
      other,
      company,
      gclid,
      firstCampaign,
      firstSourceMedium,
      firstReferral,
      recentCampaign,
      recentSourceMedium,
      recentTerm,
      firstTerm,
    } = formData || {};
    let encryptedPassword = EncryptPasswordAES(password);

    let payload = {};
    if (type === "SIGNUP") {
      payload = {
        email: email,
        password: encryptedPassword,
        emailVerified: false,
        userType: "user",
        firstName: firstname.trim(),
        lastName: lastname.trim(),
        phone: phone,
        // hearFromUs: optional === "Other" ? other : optional,
        company: company.trim(),
        gclid: gclid,
        firstCampaign: firstCampaign,
        firstSourceMedium: firstSourceMedium,
        firstReferral: firstReferral,
        recentCampaign: recentCampaign,
        recentSourceMedium: recentSourceMedium,
        recentTerm: recentTerm,
        firstTerm: firstTerm,
      };
    } else {
      payload = {
        email: email,
        emailVerified: false,
        userType: "user",
        firstName: firstname.trim(),
        lastName: lastname.trim(),
        phone: phone,
        // hearFromUs: optional === "Other" ? other : optional,
        company: company.trim(),
      };
    }

    return payload;
  };

  const signUpHandleValidation = async () => {
    const {
      firstname = "",
      lastname = "",
      email = "",
      phone = "",
      password = "",
      // optional = "",
      terms = false,
      other = "",
      company = "",
    } = formData || {};

    let tempError = {
      firstname: firstname.trim() ? "" : AppConstants.REQUIRED_FILED_ERROR,
      lastname: lastname.trim() ? "" : AppConstants.REQUIRED_FILED_ERROR,
      email: validationEmail(email),
      phone: validatePhone(phone, type),
      // optional: optional ? "" : AppConstants.REQUIRED_FILED_ERROR,
      password: type === "SIGNUP" ? validatePassword(password) : "",
      terms: terms ? "" : AppConstants.TERMS_ACCEPT_FIRST_MSG,
      // other: formData.optional.includes("Other")
      //   ? other.trim()
      //     ? ""
      //     : AppConstants.REQUIRED_FILED_ERROR
      //   : "",
      company: company.trim() ? "" : AppConstants.REQUIRED_FILED_ERROR,
    };
    let isError: boolean = true;

    if (
      tempError.email ||
      tempError.phone ||
      tempError.password ||
      tempError.firstname ||
      tempError.lastname ||
      // tempError.optional ||
      tempError.terms ||
      // tempError.other ||
      tempError.company
    ) {
      isError = false;
    }

    setFormError({ ...tempError });
    setIsLoading(false);
    return { isError: isError, tempError: tempError };
  };

  const inviteHandleValidation = async () => {
    const {
      firstname = "",
      lastname = "",
      email = "",
      phone = "",
      // optional = "",
      company = "",
    } = formData || {};

    let tempError = {
      firstname: firstname.trim() ? "" : AppConstants.REQUIRED_FILED_ERROR,
      lastname: lastname.trim() ? "" : AppConstants.REQUIRED_FILED_ERROR,
      email: validationEmail(email),
      phone: validatePhone(phone, type),
      // optional: optional ? "" : AppConstants.REQUIRED_FILED_ERROR,
      company: company.trim() ? "" : AppConstants.REQUIRED_FILED_ERROR,
    };
    let isError: boolean = true;

    if (
      tempError.email ||
      tempError.phone ||
      tempError.firstname ||
      tempError.lastname ||
      // tempError.optional ||
      tempError.company
    ) {
      isError = false;
    }

    setFormError({ ...tempError });
    setIsLoading(false);
    return { isError: isError, tempError: tempError };
  };

  const SignUpCall = async () => {
    setIsLoading(true);
    const errorData =
      type === "SIGNUP"
        ? await signUpHandleValidation()
        : await inviteHandleValidation();

    if (errorData.isError && !disabledSubmit()) {
      setIsLoading(true);
      const payload: any = getSignUpPayload();
      await SignUpFinalCall(payload, errorData.tempError, setFormError);
    }
  };

  return (
    <div>
      <div className="mt-[60px]">
        <Grid
          sx={{
            "@media (max-width: 640px)": {
              display: "block",
            },
          }}
          container
          spacing={4}
        >
          <Grid size={{ md: 12, lg: 6 }} className="!pt-[25px]">
            <InputTextField
              name={"firstname"}
              label={"First Name"}
              value={formData}
              error={formError}
              type={"text"}
              onChange={OnDataChange}
              required={true}
              maxLength={AppConstants.MAX_NAME_LENGTH}
            />
          </Grid>

          <Grid size={{ md: 12, lg: 6 }} className="!pt-[25px]">
            <InputTextField
              name={"lastname"}
              label={"Last Name"}
              value={formData}
              error={formError}
              type={"text"}
              onChange={OnDataChange}
              required={true}
              maxLength={AppConstants.MAX_NAME_LENGTH}
            />
          </Grid>

          <Grid size={{ md: 12, lg: 6 }} className="!pt-[25px]">
            <InputTextField
              name={"company"}
              label={"Company"}
              value={formData}
              error={formError}
              type={"text"}
              onChange={OnDataChange}
              required={true}
              disabled={
                type === "ADD_NEW_TEAM_MEMBER" && formData?.company?.trim()
                  ? true
                  : false
              }
              maxLength={AppConstants.MAX_NAME_LENGTH}
            />
          </Grid>

          <Grid size={{ md: 12, lg: 6 }} className="!pt-[25px]">
            <InputTextField
              name={"phone"}
              label={"Phone Number"}
              value={formData}
              error={formError}
              type={"text"}
              onChange={OnDataChange}
              required={type === "SIGNUP" ? true : false}
              maxLength={AppConstants.MAX_PHONE_NUMBER_LENGTH}
              info={"Phone number must be between 6 to 15 digits"}
            />
          </Grid>

          <Grid size={{ md: 12 }} className="!pt-[20px]">
            <InputTextField
              name={"email"}
              label={"Work Email"}
              value={formData}
              error={formError}
              type={"text"}
              onChange={OnDataChange}
              required={true}
              maxLength={AppConstants.MAX_EMAIL_LENGTH}
            />
          </Grid>

          {/* <Grid item sm={12} md={12} lg={12} className="!pt-[25px]">
            <DropDown
              name={"optional"}
              label={"How did you hear about us?"}
              value={formData["optional"]}
              error={formError["optional"]}
              placeholder={"How did you hear about us?"}
              onChange={(val: any) => {
                setFormData({
                  ...formData,
                  optional: val,
                });
              }}
              disabled={formData.optional === "Invited By Admin"}
              required={true}
              options={type === "SIGNUP" ? Optionaloptions : [...Optionaloptions, "Invited By Admin"]}
            />
            {(formData.optional || []).includes("Other") && (
              <div className="mt-5">
                <InputTextField
                  name={"other"}
                  label={""}
                  value={formData}
                  error={formError}
                  type={"text"}
                  onChange={OnDataChange}
                  required={false}
                  maxLength={AppConstants.MAX_NAME_LENGTH}
                />
              </div>
            )}
          </Grid> */}
          {type === "SIGNUP" && (
            <Grid size={{ md: 12 }} className="!pt-[25px]">
              <InputTextField
                name={"password"}
                label={"Password"}
                value={formData}
                error={formError}
                type={"password"}
                onChange={OnDataChange}
                required={true}
                maxLength={AppConstants.MAX_PASSWORD_LENGTH}
                info={ConstantTexts.PASSWORD_POLICY_INFO}
              />
            </Grid>
          )}
        </Grid>
      </div>
      {type === "ADD_NEW_TEAM_MEMBER" &&
        selectedUsers?.value === "Create New User" && (
          <div className={"mt-4 mb-8 text-base"}>
            Note: Once user accepted the invitation that user will become a part
            of team, User will be able to access the Team Account and existing
            API Keys
          </div>
        )}
      {type === "SIGNUP" ? (
        <div className="flex flex-col gap-[20px] mt-[20px]">
          <div>
            <div className="flex mt-[30px]">
              <div className="mr-3 w-[30px]">
                <CheckBoxComponent
                  value={formData.terms}
                  toggleCheck={() => {
                    setFormData({ ...formData, terms: !formData.terms });
                    setFormError({ ...formError, terms: "" });
                  }}
                />
              </div>
              <p className="text-base font-normal text-[#163545]">
                I agree to the{" "}
                <u
                  className="text-[#0494B4] font-bold cursor-pointer"
                  onClick={() => {
                    window.open(
                      "https://trestleiq.com/terms-of-service",
                      "_blank"
                    );
                  }}
                >
                  Terms of Service
                </u>
              </p>
            </div>
            {formError.terms && (
              <InputErrortext className="mt-2 flex">
                <img src={Alerttriangle} alt="required" className="mr-[10px]" />
                {formError.terms}
              </InputErrortext>
            )}
          </div>

          <div className="mt-10px w-[100%]">
            <TrestleButton
              onClick={SignUpCall}
              label="Sign Up"
              // disabled={disabledSubmit()}
              type="primary"
              icon={ForwardArrow}
            />
          </div>

          <p className="text-base font-normal text-[#163545] mt-[20px] sm:mt-10 text-center sm:text-right">
            Already a User?{" "}
            <u
              className="text-[#0494B4] font-bold cursor-pointer"
              onClick={() => {
                navigate(RouteConstants.ROUTE_LOGIN);
              }}
            >
              Sign In Here
            </u>
          </p>
        </div>
      ) : (
        <div className="flex gap-[10px] p-[20px]">
          <TrestleButton
            onClick={SignUpCall}
            label="Invite"
            type="primary"
            className="max-w-[180px] !h-[40px] !py-0 !pt-0 ml-auto mr-auto"
            // disabled={selectedUsers.length === 0}
          />
          <TrestleButton
            onClick={() => {
              setPopup(false);
            }}
            label="Cancel"
            type="secondry"
            className="max-w-[180px] !h-[40px] !py-0 !pt-0 mr-auto"
          />
        </div>
      )}
    </div>
  );
};

export default SingUpUserForm;
