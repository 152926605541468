export class RouteConstants {
  static readonly ROUTE_LOGIN = "/login";
  static readonly ROUTE_SIGNUP = "/signup";
  static readonly ROUTE_VERIFY = "/verification";
  static readonly ROUTE_RESET_PASSWORD = "/reset-password";
  static readonly ROUTE_ALREADY_ACCOUNT = "/account-exist";
  static readonly ROUTE_CONFIRM_RESET_PASSWORD = "/confirm-reset-password";
  static readonly ROUTE_FORCE_CHANGE_PASSWORD = "/change-password";

  // user routes
  static readonly ROUTE_YOUR_USAGE = "/usage";
  static readonly ROUTE_YOUR_USAGE_V2 = "/usage_v2";
  static readonly ROUTE_YOUR_API_KEY = "/api-keys";
  static readonly ROUTE_PRICING_PAGE = "/pricing";
  static readonly BATCH_UPLOAD = "/batch";
  static readonly ROUTE_API_PRODUCTS_DETAILS = "/api-keys/products-details";
  static readonly ROUTE_HUB_SPOT = "/hub-spot";
  static readonly ROUTE_HUB_SPOT_INTEGRATION = "/hub-spot/integration";

  static readonly ROUTE_HOME = this.ROUTE_YOUR_API_KEY;

  // admin routes
  static readonly ROUTE_ADMIN_PRODUCT_ACCESS = "/admin/product-access";
  static readonly ROUTE_ADMIN_ALL_USERS = "/admin/users";
  static readonly ROUTE_ADMIN_USAGES = "/admin/usages";
  static readonly ROUTE_ADMIN_HOME = this.ROUTE_ADMIN_ALL_USERS;
  static readonly ROUTE_ADMIN_TEAMS = "/admin/teams";
  static readonly ROUTE_ADMIN_BATCH_UPLOAD = "/admin/batch";

  // home routes
  static readonly ROUTE_DUMMY_HOME = "/";

  // Lead Assessment routes
  static readonly ROUTE_LEAD_ASSESSMENT_FORM = "/data-assessment";
  static readonly ROUTE_LEAD_ASSESSMENT_DETAILS =
    "/data-assessment/report/:uuid";
}
