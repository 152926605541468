import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { MainHeaderText } from "../../Shared/CommonComponents";
import { TabTextComponent } from "../../Shared/ComponentDrawer/componentDrawer";
import SelfServeTabComponent from "./SelfServeTab";
import LoaderComponent from "../../Shared/LoaderComponent";
import EnterpriseTabComponent from "./EnterpriseTab";
import BestBuyLogo from "../../../assets/best_buy_logo1.svg";
import TwilloLogo from "../../../assets/twillio_logo.svg";
import SmartAssetLogo from "../../../assets/smart_asset_logo.svg";
import ActiveProspectLogo from "../../../assets/Active_Prospect_Logo1.svg";
import HiyaLogo from "../../../assets/hiya_logo.svg";
import styled from "@emotion/styled";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";

const PricingPage = ({}) => {
  const location = useLocation();
  const walletData = useAppSelector((state) => state.walletReducer.walletData);

  const isTrialUser =
    walletData?.walletDisplayType === "TRIAL" ||
    walletData?.walletDisplayType === "TRIAL_EXPIRED";
  // wallet exists & user is from a different team but same company, block top-up
  const isTopUpBlocked =
    isTrialUser &&
    walletData?.walletId != null &&
    parseFloat(walletData?.balance) != 0.0;

  const [selectedTab, setSelectedTab] = useState<"ENTERPRISE" | "SELF_SERVE">();

  useEffect(() => {
    if (isTrialUser && !isTopUpBlocked) {
      setSelectedTab("SELF_SERVE");
    } else {
      setSelectedTab("ENTERPRISE");
    }
  }, [walletData]);

  const [loading, setLoading] = useState<boolean>(false);
  return (
    <>
      {selectedTab && (
        <Box>
          <LoaderComponent loader={loading} />
          <Box className="bg-[#F5F5F5] pt-[50px]">
            <MainHeaderText>Pricing to meet your business needs</MainHeaderText>
            <Box className="flex px-[45px] mt-[48px] justify-center">
              <TabComponent
                selected={selectedTab === "ENTERPRISE"}
                key={"DETAILS"}
                onClick={() => {
                  setSelectedTab("ENTERPRISE");
                }}
              >
                Enterprise
              </TabComponent>
              {walletData?.walletDisplayType !== "UNLIMITED" &&
                !isTopUpBlocked && (
                  <TabComponent
                    selected={selectedTab === "SELF_SERVE"}
                    key={"TEAMS"}
                    onClick={() => {
                      setSelectedTab("SELF_SERVE");
                    }}
                  >
                    Self-Serve
                  </TabComponent>
                )}
            </Box>
          </Box>
          <Box sx={{ height: "100%" }}>
            {selectedTab === "ENTERPRISE" && <EnterpriseTabComponent />}
            {selectedTab === "SELF_SERVE" && (
              <SelfServeTabComponent setLoading={setLoading} />
            )}
          </Box>
          <Box className="flex flex-col gap-[20px] items-center py-[56px] bg-[#F5F5F5]">
            <Box className="brandingPageText">
              Trestle is trusted by leading enterprise companies{" "}
              <span className="text-[#0494B4]">serving 300M+ queries</span> in
              the US and Canada
            </Box>
            <Box className="flex gap-[20px] items-center flex-wrap ">
              <Box
                component={"img"}
                src={BestBuyLogo}
                alt="trestle logo"
                className="w-[60px] h-[60px] mx-auto"
              />
              <Box
                component={"img"}
                src={ActiveProspectLogo}
                alt="trestle logo"
                className="w-[112px] h-[20px] mx-auto"
              />
              <Box
                component={"img"}
                src={TwilloLogo}
                alt="trestle logo"
                className="w-[104px] h-[38px] mx-auto"
              />
              <Box
                component={"img"}
                src={SmartAssetLogo}
                alt="trestle logo"
                className="w-[104px] h-[21px] mx-auto"
              />
              <Box
                component={"img"}
                src={HiyaLogo}
                alt="trestle logo"
                className="w-[60px] h-[34px] mx-auto"
              />
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default PricingPage;

export const TabComponent = styled.div<{ selected: boolean }>`
  font-size: 24px;
  font-weight: ${(props: any) => (props.selected ? "500" : "400")};
  line-height: 29px;
  border-bottom: ${(props: any) =>
    props.selected ? "8px solid #0494B4" : "none"};
  cursor: pointer;
  margin-right: 30px;
  color: ${(props: any) => (props.selected ? "#163545" : "#566D76")};
  padding-bottom: 21px;
`;
