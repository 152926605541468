import React from "react";

import { useEffect, useState } from "react";
import DialogPopup from "../../Shared/DialogPopup";
import Select from "react-select";
import { Box, Stack, Tooltip } from "@mui/material";
import TrestleButton from "../../Shared/TrestleButton";
import { HTTP_STATUS } from "../../../http/constants/http.status";
import { WalletInputErrortext } from "../../Shared/InputTextField";
import { useDispatch } from "react-redux";
import { setToast } from "../../../app/toastReducer";
import { AppConstants } from "../../../constants/AppConstants";
import {
  Text14pxMedium,
  Text16pxBold,
  Text16pxMedium,
  Text20pxMedium,
} from "../../Shared/CommonComponents";
import { handleMixPanel } from "../../../utils/mixpanelUtils";
import { MixPanelEvents } from "../../../constants/MixPanelEvents";
import { submitHubspotListForBatchExecution } from "./HubSpotService";
import LoaderComponent from "../../Shared/LoaderComponent";
import { InfoIcon } from "../BatchUpload/BatchUploadPopUp";

interface ApiKeyProduct {
  displayName: string;
  productName: string;
}


interface RunHubSpotListPopUpProps {
  open: boolean;
  selectedList: any;
  setOpen: any;
  setIsLoading?: any;
  apiKeysAndProducts: Record<string, ApiKeyProduct>;
  callGetBatchAPI?: any;
}

const RunHubSpotListPopUp = ({
  open,
  setOpen,
  setIsLoading,
  apiKeysAndProducts,
  callGetBatchAPI,
  selectedList,
}: RunHubSpotListPopUpProps) => {
  const dispatch = useDispatch();

  const [selectedKey, setSelectedKey] = useState<any>();

  const [selectedProduct, setSelectedProduct] = useState<any>();

  const [apiKeyList, setApiKeyList] = useState<any>([]);

  const [productList, setProductList] = useState<any>([]);

  const [errorMessage, setErrorMessage] = useState<any>(null);

  const [file, setFile] = useState<any>(null);

  const [disableSubmit, setDisableSubmit] = useState<boolean>(true);

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const apiKeys = Object.entries(apiKeysAndProducts).map(([k, v]) => ({
      label: k,
      value: v,
      id: k,
    }));
    setErrorMessage(null);
    setSelectedKey(null);
    setSelectedProduct(null);

    setApiKeyList(apiKeys);
    if (apiKeys.length === 1) {
      setSelectedKey(apiKeys[0]);
      handleApiKeySelect(apiKeys[0]);
    }
    setFile(selectedList)
  }, [selectedList,apiKeysAndProducts, open]);

  useEffect(() => {
    if (
      file != undefined &&
      selectedKey != undefined &&
      selectedProduct != undefined
    )
      setDisableSubmit(false);
  }, [selectedKey, selectedProduct, file]);

  const handleChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      if (file.type === "text/csv") {
        setFile(file);
      }
    }
  };

  const handleApiKeySelect = (data: any) => {
    setSelectedKey(data);
    const products = data.value.map((v: any) => {
      const [baseName, secondName, version] = v.apiName.split(" ");
      return {
        label: `${v.displayName.split(" API")[0]} ${version} API`,
        value: v.apiName,
        id: v.apiName,
      };
    });
    setProductList(products);
  };

  const handleProductSelect = (data: any) => {
    setSelectedProduct(data);
  };

  const handleSubmit = async (event: any) => {
    setLoading(true);
    const payload = {
      listId : file.listId,
      listName : file.name,
      api_key : selectedKey.id,
      product_name : selectedProduct.id
    }
    const submitResponse = await submitHubspotListForBatchExecution(payload);
    setLoading(false);
    const { status = 401, data = {} } = submitResponse || {};
  
    if (status === HTTP_STATUS.HTTP_OK) {
      if (
        data.data ===
        "The file has been uploaded successfully and is now being processed."
      ) {
        setOpen(false);
        setSelectedProduct(null);
        setSelectedKey(null);
        setFile(null);
        dispatch(
          setToast({
            open: true,
            severity: AppConstants.TOAST_SUCCESS,
            msg: data.data,
          })
        );
        callGetBatchAPI();
      } else {
        setErrorMessage(data.data);
        handleMixPanel(MixPanelEvents.BATCH_UPLOAD_ERROR, {
          error: data.data,
        });
      }
    } else {
      const { details } = data.message;

      dispatch(
        setToast({
          open: true,
          severity: AppConstants.TOAST_ERROR,
          msg: details,
        })
      );
    }

  };

  return (
    <>
    <LoaderComponent loader={loading} />
    <DialogPopup
      open={open}
      applyZIndex={false}
      onClose={(val: any) => {
        setOpen(false);
        setErrorMessage(null);
        setSelectedKey(null);
        setSelectedProduct(null);
      }}
      closeButton={true}
      title={""}
      className="!p-0"
      component={
        <Box className="pb-[50px] w-[700px]">
          {/* header */}
          <Box className="w-full py-8 px-[20px] bg-[#F3F5F6] text-[#163545] mb-6">
            <Box className="text-xl font-bold flex">Confirm List Run</Box>
          </Box>
          {/* header */}

          <Box className="px-[20px] flex flex-col gap-[32px]">
            <Box>
              {errorMessage && (
                <WalletInputErrortext>{errorMessage}</WalletInputErrortext>
              )}
            </Box>
            <Stack spacing={1}>
              <Text20pxMedium>List Selected to be run</Text20pxMedium>
              <Text14pxMedium>{selectedList?.name}</Text14pxMedium>
            </Stack>
            {apiKeyList.length > 1 && (
              <Box id="step1" className="flex flex-col gap-[8px]">
                <Text16pxBold className="!font-bold">Step-1</Text16pxBold>
                <Box className="flex justify-between items-center">
                  <Text16pxMedium>Choose API Key</Text16pxMedium>
                  <Box className="flex w-[70%] items-center">
                    <Box className="w-[100%] mr-2">
                      <Select
                        options={apiKeyList}
                        placeholder="Select API Key"
                        value={selectedKey}
                        onChange={handleApiKeySelect}
                        className="select-dropdown"
                      />
                    </Box>
                    <Tooltip
                      title={"Only available API keys"}
                      arrow
                      sx={{
                        width: "auto",
                        zIndex: 111112,
                        height: "fit-content",
                      }}
                    >
                      <span className="h-fit">{InfoIcon}</span>
                    </Tooltip>
                  </Box>
                </Box>
              </Box>
            )}
            <Box id="step2" className="flex flex-col gap-[8px]">
              {apiKeyList.length > 1 ? (
                <Text16pxBold className="!font-bold">Step-2</Text16pxBold>
              ) : (
                <Text16pxBold className="!font-bold">Step-1</Text16pxBold>
              )}
              <Box className="flex justify-between items-center">
                <Text16pxMedium>Choose Product</Text16pxMedium>
                <Box className="flex w-[70%] items-center">
                  <Box className="w-[100%] mr-2">
                    <Select
                      options={productList}
                      placeholder="Select Product"
                      value={selectedProduct}
                      onChange={handleProductSelect}
                      className="select-dropdown"
                    />
                  </Box>
                  <Tooltip
                    title={
                      "Only products you have access to will be available for Batch Upload"
                    }
                    arrow
                    sx={{
                      width: "100px",
                      zIndex: 111112,
                      height: "fit-content",
                    }}
                  >
                    <span className="h-fit">{InfoIcon}</span>
                  </Tooltip>
                </Box>
              </Box>
            </Box>

            <Box id="step4" className="flex w-[70%] ml-auto">
              <TrestleButton
                onClick={handleSubmit}
                label="Submit"
                disabled={disableSubmit}
                type="primary"
                className="max-w-[180px] !h-[40px] !py-0 !pt-0 mr-auto"
              />
              <TrestleButton
                onClick={() => {
                  setOpen(false);
                }}
                label="Cancel"
                type="secondry"
                className="max-w-[180px] !h-[40px] !py-0 !pt-0 ml-3 mr-auto"
              />
            </Box>
          </Box>
        </Box>
      }
    />
    </>
  );
};

export default RunHubSpotListPopUp;
