import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { useNavigate } from "react-router-dom";
import { BrandingPage } from "../../Shared/BrandingPage";
import {
  MainHeaderText,
  BrandingPageChildContainer,
  OldPortalDiv,
} from "../../Shared/CommonComponents";
import InputTextField from "../../Shared/InputTextField";
import Grid from "@mui/material/Grid2";
import TrestleButton from "../../Shared/TrestleButton";
import { ForwardArrow } from "../../../assets/svgs";
import { RouteConstants } from "../../../constants/RouteConstants";
import { validationEmail, validatePassword } from "../../../utils/validations";
import { AppConstants } from "../../../constants/AppConstants";
import { ConstantTexts } from "../../../constants/ConstantTexts";
import Auth from "@aws-amplify/auth";
import { setToast } from "../../../app/toastReducer";
import {
  setTokens,
  setForceUserSession,
  setUserMode,
  setUserData,
  setIsAdmin,
} from "../../../app/authReducer";
import { sign } from "crypto";
import WaringPopup from "../../Shared/WarningPopup";
import { handleMixPanel } from "../../../utils/mixpanelUtils";
import { MixPanelEvents } from "../../../constants/MixPanelEvents";
import Cookies from "js-cookie";
import { Helmet } from "react-helmet";
import axios from "axios";

export const Login = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [formData, setFormData] = useState<any>({
    email: "",
    password: "",
  });
  const [formError, setFormError] = useState<any>({
    email: "",
    password: "",
  });

  const [_token, setToken] = useState<any>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [openPopup, setOpenPopup] = useState<boolean>(false);

  const API_BASE_URL: string =
    process.env.REACT_APP_API_URL || "http://localhost";

  const headers = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Cache-Control":
      "no-store, no-cache, max-age=0, must-revalidate, proxy-revalidate",
    Accept: "application/json",
    Pragma: "no-cache",
    "X-Content-Type-Options": "nosniff",
    "Strict-Transport-Security": "max-age=31536000; includeSubDomains",
    "X-Frame-Options": "SAMEORIGIN",
  };

  const setCookie = (name: string, value: string) => {
    Cookies.set(name, value, {
      expires: 1,
      domain: ".trestleiq.com",
      secure: true,
      sameSite: "none",
      // path: "/",
    });
  };

  useEffect(() => {
    try {
      if (sessionStorage.getItem("lastMixpanelEvent") !== "Visit Login Page") {
        handleMixPanel(MixPanelEvents.USER_LOGIN, {});
      }
    } catch (e) {
      console.log(e);
    }
  }, []);

  const OnDataChange = (event: any) => {
    const { value, name } = event.target;

    setFormData({ ...formData, [name]: value });
  };

  const getUserData = async (idToken: string) => {
    let uri: string = `${API_BASE_URL}${AppConstants.GET_USER}`;
    await axios
      .get(uri, {
        headers: {
          ...headers,
          Authorization: `Bearer ${idToken}`,
        },
      })
      .then((response) => {
        dispatch(setUserData(response.data.data));
        const admin: any = response.data.data.roles.filter(
          (ele: any) => ele.roleName === "ROLE_ADMIN"
        );
        if (admin.length) {
          localStorage.setItem("isAdmin", "true");
          dispatch(setUserMode("ADMIN"));
          dispatch(setIsAdmin(true));
          setIsLoading(false);
          navigate(RouteConstants.ROUTE_ADMIN_HOME);
        } else {
          localStorage.setItem("isAdmin", "false");
          dispatch(setUserMode("USER"));
          dispatch(setIsAdmin(false));
          setIsLoading(false);
          navigate(RouteConstants.ROUTE_HOME);
        }
      });
  };
  const disabledSubmit = () => {
    const { email = "", password = "" } = formData || {};
    return !(email.trim() && password.trim());
  };

  const handleValidation = () => {
    const { email = "", password = "" } = formData || {};
    let isError: boolean = true;

    let tempError = {
      email: validationEmail(email),
      password: validatePassword(password),
    };

    if (tempError.email || tempError.password) {
      isError = false;
    }

    setFormError({ ...tempError });
    return isError;
  };

  const SignInCall = async () => {
    const isError: boolean = handleValidation();

    if (isError && !disabledSubmit()) {
      setIsLoading(true);

      try {
        const response = await Auth.signIn(formData.email, formData.password);
        if (
          response?.challengeName ===
          AppConstants.FORCE_CHANGE_PASSWORD_MIGRATION
        ) {
          dispatch(setForceUserSession(response));
          navigate(RouteConstants.ROUTE_FORCE_CHANGE_PASSWORD);
        }

        if (response.signInUserSession) {
          const res = response.signInUserSession;
          let token = {
            idToken: res.idToken.jwtToken,
            accessToken: res.accessToken.jwtToken,
            refreshToken: res.refreshToken.token,
          };
          getUserData(res.idToken.jwtToken);
          dispatch(setTokens(token));
          setToken(token);
          const newDate = new Date();
          setCookie(
            "developerportal",
            `${
              formData.email
            }|login|${newDate.getUTCHours()}:${newDate.getUTCMinutes()}:${newDate.getUTCSeconds()}`
          );
        }

        setIsLoading(false);
      } catch (error: any) {
        setIsLoading(false);
        dispatch(
          setToast({
            open: true,
            severity: AppConstants.TOAST_ERROR,
            msg:
              error.message === "User is disabled."
                ? "Your Account has been deactivated. Please contact your admin."
                : error.message,
            autoCloseTime: 15000,
          })
        );
        if (error.message === "User is not confirmed.") {
          sessionStorage.setItem(
            "signup-data",
            JSON.stringify({ email: formData.email, cognitoId: "" })
          );
          navigate(RouteConstants.ROUTE_VERIFY);
        }
        console.log("error: ", error.message);
      }
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Trestle Developer Portal - Login</title>
        <link
          rel="canonical"
          href={`${process.env.REACT_APP_DEV_PORTAL_URL}login`}
        />
      </Helmet>
      <BrandingPage loader={isLoading}>
        <BrandingPageChildContainer>
          <MainHeaderText>{ConstantTexts.SIGNIN_HEADER}</MainHeaderText>

          <div className="mt-[60px]">
            <Grid
              className="!block sm:!flex sm:!justify-center sm:!items-center"
              container
              spacing={4}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  SignInCall();
                }
              }}
            >
              <Grid size={{ sm: 12 }} className="!pt-[0px] sm:!pt-[25px]">
                <InputTextField
                  name={"email"}
                  label={"Email"}
                  value={formData}
                  error={formError}
                  type={"text"}
                  onChange={OnDataChange}
                  required={true}
                  maxLength={AppConstants.MAX_EMAIL_LENGTH}
                />
              </Grid>

              <Grid size={{ sm: 12 }} className="!pt-[25px]">
                <InputTextField
                  name={"password"}
                  label={"Password"}
                  value={formData}
                  error={formError}
                  type={"password"}
                  onChange={OnDataChange}
                  required={true}
                  maxLength={AppConstants.MAX_PASSWORD_LENGTH}
                />
              </Grid>
            </Grid>
          </div>

          <div
            className="mt-[20px] sm:mt-[50px] mb-[20px] sm:mb-[30px]"
            style={{ marginTop: "26px" }}
          >
            <p className="text-base font-normal text-[#163545]">
              <u
                className="text-[#0494B4] font-bold cursor-pointer"
                onClick={() => {
                  navigate(RouteConstants.ROUTE_RESET_PASSWORD);
                }}
              >
                Forgot your password?
              </u>
            </p>
          </div>

          <div className="w-[100%]">
            <TrestleButton
              onClick={SignInCall}
              label="Sign In"
              disabled={disabledSubmit()}
              type="primary"
              icon={ForwardArrow}
              className="!w-full"
            />
          </div>

          <p className="text-base font-normal text-[#163545] mt-[20px] sm:mt-10 text-center sm:text-right">
            New User?{" "}
            <u
              className="text-[#0494B4] font-bold cursor-pointer"
              onClick={() => {
                navigate(RouteConstants.ROUTE_SIGNUP);
              }}
            >
              Create an Account
            </u>
          </p>
        </BrandingPageChildContainer>
      </BrandingPage>

      <WaringPopup
        open={openPopup}
        close={() => {
          setOpenPopup(false);
        }}
        title={"About Legacy Accounts"}
        text={""}
        htmlElement={
          <div>
            <div>
              Good news! We launched our new and improved developer portal on
              September 29, 2023. For developers who signed up before that date,
              this means a few things:
            </div>

            <div className="flex mt-4">
              <div className="min-w-[20px] text-[#163545] font-bold my-auto bg-[#E5F4F7] text-center text-sm">
                1
              </div>
              <div className="pl-3">
                We are in the process of migrating accounts to the new portal,
                and you should receive notification soon.
              </div>
            </div>

            <div className="flex mt-4">
              <div className="min-w-[20px] text-[#163545] font-bold my-auto bg-[#E5F4F7] text-center text-sm">
                2
              </div>
              <div className="pl-3">
                You can continue to access the legacy portal in the meantime.
              </div>
            </div>
          </div>
        }
        buttonLable="Legacy Sign In"
        cancelLabel="Go Back"
        buttonIcon={ForwardArrow}
        onAction={() => {
          window.open(
            "https://dev-portal.trestleiq.com/accounts/login",
            "_blank"
          );
          setOpenPopup(false);
        }}
      />
    </>
  );
};
