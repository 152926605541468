export const ForwardArrow = (
  <svg
    width="14"
    height="inherit"
    viewBox="0 0 14 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.4697 2.03033C8.1768 1.73744 8.1768 1.26256 8.4697 0.969671C8.7626 0.676781 9.2374 0.676781 9.5303 0.969671L13.5303 4.9697C13.8232 5.2626 13.8232 5.7374 13.5303 6.0303L9.5303 10.0303C9.2374 10.3232 8.7626 10.3232 8.4697 10.0303C8.1768 9.7374 8.1768 9.2626 8.4697 8.9697L11.1893 6.25H1.5C1.08579 6.25 0.75 5.9142 0.75 5.5C0.75 5.0858 1.08579 4.75 1.5 4.75H11.1893L8.4697 2.03033Z"
      fill="currentColor"
    />
  </svg>
);

export const BackwardArrow = (
  <svg
    width="14"
    height="inherit"
    viewBox="0 0 14 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.5303 8.46967C5.8232 8.76256 5.8232 9.23744 5.5303 9.53033C5.2374 9.82322 4.7626 9.82322 4.4697 9.53033L0.4697 5.5303C0.1768 5.2374 0.1768 4.7626 0.4697 4.4697L4.4697 0.469702C4.7626 0.176802 5.2374 0.176802 5.5303 0.469702C5.8232 0.762602 5.8232 1.2374 5.5303 1.5303L2.8107 4.25L12.5 4.25C12.9142 4.25 13.25 4.5858 13.25 5C13.25 5.4142 12.9142 5.75 12.5 5.75L2.8107 5.75L5.5303 8.46967Z"
      fill="currentColor"
    />
  </svg>
);

export const ClockIcon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 6V12H16.5M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const ResendIcon = (
  <svg
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.54567 5.16273C3.33045 5.10595 3.15157 4.95651 3.05741 4.75482C2.96325 4.55313 2.96354 4.32004 3.0582 4.11859L4.71895 0.584199C4.84271 0.320809 5.10766 0.152789 5.39868 0.153149C5.68969 0.153509 5.95422 0.322189 6.07733 0.585879L6.80996 2.15511C6.83615 2.14482 6.8632 2.13589 6.89103 2.12843C11.2383 0.963569 15.7069 3.54347 16.8717 7.8908C18.0366 12.2382 15.4567 16.7067 11.1094 17.8716C6.76204 19.0364 2.29351 16.4565 1.12864 12.1092C0.758209 10.7267 0.76655 9.3299 1.09098 8.0311C1.19137 7.6293 1.59853 7.3849 2.00039 7.4853C2.40226 7.5856 2.64665 7.9928 2.54626 8.3947C2.28197 9.4527 2.27468 10.5907 2.57753 11.721C3.52798 15.2681 7.17403 17.3731 10.7211 16.4227C14.2683 15.4722 16.3733 11.8262 15.4229 8.2791C14.4879 4.7899 10.9449 2.69609 7.45333 3.53314L8.19253 5.11644C8.31563 5.38013 8.27503 5.69124 8.08853 5.91457C7.90193 6.1379 7.60303 6.23314 7.32163 6.15891L3.54567 5.16273Z"
      fill="currentColor"
    />
  </svg>
);

export const MailIcon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.804026 4.3533C0.523886 6.9562 0.536376 9.9582 0.926196 12.5505C1.1419 13.9849 2.31257 15.0845 3.75764 15.2102L5.26787 15.3414C8.41694 15.6152 11.5837 15.6152 14.7328 15.3414L16.243 15.2102C17.6881 15.0845 18.8588 13.9849 19.0745 12.5505C19.4643 9.9582 19.4768 6.9564 19.1966 4.35345C19.1604 4.05169 19.1197 3.75034 19.0745 3.44949C18.8588 2.0151 17.6881 0.915452 16.243 0.789832L14.7328 0.658552C11.5837 0.384812 8.41694 0.384812 5.26787 0.658552L3.75764 0.789832C2.31257 0.915452 1.1419 2.0151 0.926196 3.44949C0.880966 3.75028 0.840246 4.05159 0.804026 4.3533ZM5.39777 2.15292C8.46034 1.88669 11.5403 1.88669 14.6029 2.15292L16.1131 2.2842C16.8674 2.34977 17.4785 2.92379 17.5911 3.67254C17.6028 3.7503 17.6142 3.82809 17.6253 3.90592L12.0643 6.9954C10.7807 7.7085 9.21994 7.7085 7.93633 6.9954L2.37539 3.90596C2.38645 3.82812 2.39783 3.75031 2.40952 3.67254C2.52212 2.92379 3.13321 2.34977 3.88754 2.2842L5.39777 2.15292ZM17.8088 5.51989C18.0028 7.7876 17.9302 10.0725 17.5911 12.3274C17.4785 13.0762 16.8674 13.6502 16.1131 13.7158L14.6029 13.8471C11.5403 14.1133 8.46034 14.1133 5.39777 13.8471L3.88754 13.7158C3.13321 13.6502 2.52212 13.0762 2.40952 12.3274C2.07043 10.0725 1.99787 7.7877 2.19184 5.51992L7.20786 8.3066C8.94454 9.2714 11.0561 9.2714 12.7927 8.3066L17.8088 5.51989Z"
      fill="currentColor"
    />
  </svg>
);

export const OpenEye = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className="w-6 h-6"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
    />
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
    />
  </svg>
);

export const ClosedEye = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className="w-6 h-6"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
    />
  </svg>
);

export const ClosePopup = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className="w-6 h-6"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
    />
  </svg>
);

export const DownArrow = (
  <svg
    width="10"
    height="25"
    viewBox="0 0 10 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.5303 0.969668C9.8232 1.26256 9.8232 1.73744 9.5303 2.0303L5.5303 6.0303C5.2374 6.3232 4.7626 6.3232 4.4697 6.0303L0.469668 2.0303C0.176777 1.73744 0.176777 1.26256 0.469668 0.969668C0.762558 0.676777 1.23744 0.676777 1.53033 0.969668L5 4.4393L8.4697 0.969668C8.7626 0.676777 9.2374 0.676777 9.5303 0.969668Z"
      fill="currentColor"
    />
  </svg>
);

export const HamburgerIcon = (
  <svg
    width="24"
    height="14"
    viewBox="0 0 24 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="24" height="2" fill="#44DAD3" />
    <rect y="6" width="24" height="2" fill="#44DAD3" />
    <rect y="12" width="16" height="2" fill="#44DAD3" />
  </svg>
);

export const CopyClipboardIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 3.25C5.82436 3.25 3.25 5.82436 3.25 9V16.1069C3.25 16.5211 3.58579 16.8569 4 16.8569C4.41421 16.8569 4.75 16.5211 4.75 16.1069V9C4.75 6.65279 6.65279 4.75 9 4.75H16.0129C16.4271 4.75 16.7629 4.41421 16.7629 4C16.7629 3.58579 16.4271 3.25 16.0129 3.25H9Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.4026 6.79327C15.1616 6.43105 11.8384 6.43105 8.59748 6.79327C7.6742 6.89646 6.93227 7.62305 6.82344 8.55349C6.43906 11.84 6.43906 15.16 6.82344 18.4465C6.93227 19.377 7.6742 20.1035 8.59748 20.2067C11.8384 20.569 15.1616 20.569 18.4026 20.2067C19.3258 20.1035 20.0678 19.377 20.1766 18.4465C20.561 15.16 20.561 11.84 20.1766 8.55349C20.0678 7.62305 19.3258 6.89646 18.4026 6.79327ZM8.76409 8.28399C11.8943 7.93414 15.1057 7.93414 18.2359 8.28399C18.4733 8.31051 18.6599 8.49822 18.6867 8.72774C19.0576 11.8984 19.0576 15.1016 18.6867 18.2723C18.6599 18.5018 18.4733 18.6895 18.2359 18.716C15.1057 19.0659 11.8943 19.0659 8.76409 18.716C8.52674 18.6895 8.34013 18.5018 8.31329 18.2723C7.94245 15.1016 7.94245 11.8984 8.31329 8.72774C8.34013 8.49822 8.52674 8.31051 8.76409 8.28399Z"
      fill="currentColor"
    />
  </svg>
);

export const LinkIcon = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.2219 3.03125L5.06563 12.1875C4.74375 12.5594 4.18125 12.5969 3.8125 12.275C3.44375 11.9531 3.40312 11.3906 3.725 11.0219L3.82188 10.925L12.9594 1.77813H9.77812C9.2875 1.77813 8.89062 1.38125 8.89062 0.890625C8.89062 0.4 9.2875 0 9.77812 0H15.1125C15.6031 0 16 0.396875 16 0.8875V6.22188C16 6.7125 15.6031 7.10938 15.1125 7.10938C14.6219 7.10938 14.225 6.7125 14.225 6.22188V3.03125H14.2219ZM12.4438 9.77812C12.4438 9.2875 12.8406 8.89062 13.3313 8.89062C13.8219 8.89062 14.2188 9.2875 14.2188 9.77812V14.2219C14.2188 15.2031 13.4219 16 12.4406 16H1.77813C0.796875 16 0 15.2031 0 14.2219V3.55625C0 2.57812 0.8 1.77813 1.77813 1.77813H6.22188C6.7125 1.77813 7.10938 2.175 7.10938 2.66563C7.10938 3.15625 6.7125 3.55312 6.22188 3.55312H1.77813V14.2188H12.4438V9.77812Z"
      fill="currentColor"
    />
  </svg>
);

export const SearchIcon = (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.78907 9.58426C6.80134 11.1763 3.89177 11.051 2.04918 9.20836C0.0721488 7.23136 0.0721488 4.02596 2.04918 2.04893C4.02621 0.0719047 7.23162 0.0719047 9.20862 2.04893C11.0512 3.89152 11.1765 6.80108 9.58452 8.78881L13.4513 12.6555C13.671 12.8752 13.671 13.2314 13.4513 13.451C13.2316 13.6707 12.8754 13.6707 12.6558 13.451L8.78907 9.58426ZM2.84467 8.41291C1.30699 6.87518 1.30699 4.38212 2.84467 2.84443C4.38237 1.30674 6.87544 1.30674 8.41317 2.84443C9.94969 4.38099 9.95082 6.87151 8.41654 8.40953C8.41542 8.41066 8.41429 8.41171 8.41309 8.41291C8.41197 8.41403 8.41084 8.41516 8.40979 8.41628C6.87177 9.95056 4.38124 9.94943 2.84467 8.41291Z"
      fill="#163545"
    />
  </svg>
);

export const SideArrow = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="12"
    viewBox="0 0 8 13"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.29289 0.626269C0.68341 0.235737 1.31658 0.235737 1.70706 0.626269L7.0404 5.9596C7.43093 6.35014 7.43093 6.9832 7.0404 7.37374L1.70706 12.7071C1.31658 13.0976 0.68341 13.0976 0.29289 12.7071C-0.09763 12.3166 -0.09763 11.6834 0.29289 11.2929L4.91906 6.66667L0.29289 2.0404C-0.09763 1.64987 -0.09763 1.0168 0.29289 0.626269Z"
      fill="currentColor"
    />
  </svg>
);

export const CalenderIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className="w-[18px] h-[18px]"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5"
    />
  </svg>
);

export const DeleteItemCross = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className="w-6 h-6"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M6 18L18 6M6 6l12 12"
    />
  </svg>
);

export const KeyIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M7 10C5.89543 10 5 10.8954 5 12C5 13.1046 5.89543 14 7 14C8.10457 14 9 13.1046 9 12C9 10.8954 8.10457 10 7 10Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7 6.25C3.82436 6.25 1.25 8.82436 1.25 12C1.25 15.1756 3.82436 17.75 7 17.75C9.18057 17.75 11.0762 16.5363 12.0508 14.75H15.25V17C15.25 17.4142 15.5858 17.75 16 17.75H19.5C19.9142 17.75 20.25 17.4142 20.25 17V14.75H22C22.4142 14.75 22.75 14.4142 22.75 14V11C22.75 10.0335 21.9665 9.25 21 9.25H12.0508C11.0762 7.46371 9.18057 6.25 7 6.25ZM2.75 12C2.75 9.65279 4.65279 7.75 7 7.75C8.74161 7.75 10.2402 8.79769 10.8967 10.3003C11.016 10.5734 11.2859 10.75 11.584 10.75H21C21.1381 10.75 21.25 10.8619 21.25 11V13.25H19.5C19.0858 13.25 18.75 13.5858 18.75 14V16.25H16.75V14C16.75 13.5858 16.4142 13.25 16 13.25H11.584C11.2859 13.25 11.016 13.4266 10.8967 13.6997C10.2402 15.2023 8.74161 16.25 7 16.25C4.65279 16.25 2.75 14.3472 2.75 12Z"
      fill="currentColor"
    />
  </svg>
);

export const BatchUploadIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 14 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.53033 5.78028C3.23744 6.07318 2.76256 6.07318 2.46967 5.78028C2.17678 5.48738 2.17678 5.01258 2.46967 4.71968L6.4697 0.719675C6.7626 0.426775 7.2374 0.426775 7.5303 0.719675L11.5303 4.71968C11.8232 5.01258 11.8232 5.48738 11.5303 5.78028C11.2374 6.07318 10.7626 6.07318 10.4697 5.78028L7.75 3.06067V11.75C7.75 12.1642 7.4142 12.5 7 12.5C6.5858 12.5 6.25 12.1642 6.25 11.75V3.06067L3.53033 5.78028Z"
      fill="currentColor"
    />
    <path
      d="M1 13V16H13V13"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const PieChartIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.25 5.78833C7.59833 6.16362 4.75 9.24918 4.75 13C4.75 17.0041 7.99594 20.25 12 20.25C15.7508 20.25 18.8364 17.4017 19.2117 13.75H12C11.5858 13.75 11.25 13.4142 11.25 13V5.78833ZM3.25 13C3.25 8.16751 7.16751 4.25 12 4.25C12.4142 4.25 12.75 4.58579 12.75 5V12.25H20C20.4142 12.25 20.75 12.5858 20.75 13C20.75 17.8325 16.8325 21.75 12 21.75C7.16751 21.75 3.25 17.8325 3.25 13Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.5 4.67373V9.50008H20.3264C19.7633 7.11568 17.8844 5.23676 15.5 4.67373ZM14.998 3.06173C18.6183 3.51226 21.4878 6.38175 21.9384 10.0021C22.0066 10.5502 21.5523 11.0001 21 11.0001H14.5C14.2239 11.0001 14 10.7763 14 10.5001V4.00008C14 3.4478 14.45 2.99353 14.998 3.06173Z"
      fill="currentColor"
    />
  </svg>
);

export const BookIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M8.25 8C8.25 7.58579 8.58579 7.25 9 7.25H16C16.4142 7.25 16.75 7.58579 16.75 8C16.75 8.41421 16.4142 8.75 16 8.75H9C8.58579 8.75 8.25 8.41421 8.25 8Z"
      fill="currentColor"
    />
    <path
      d="M9 10.25C8.58579 10.25 8.25 10.5858 8.25 11C8.25 11.4142 8.58579 11.75 9 11.75H14C14.4142 11.75 14.75 11.4142 14.75 11C14.75 10.5858 14.4142 10.25 14 10.25H9Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.5 3.25C5.87665 3.25 3.75 5.37665 3.75 8V18C3.75 20.0711 5.42893 21.75 7.5 21.75H18.5C19.4665 21.75 20.25 20.9665 20.25 20V5C20.25 4.0335 19.4665 3.25 18.5 3.25H8.5ZM18.75 14.25V5C18.75 4.86193 18.6381 4.75 18.5 4.75H8.5C6.70507 4.75 5.25 6.20507 5.25 8V14.9997C5.87675 14.529 6.6558 14.25 7.5 14.25H18.75ZM18.75 15.75H7.5C6.25736 15.75 5.25 16.7574 5.25 18C5.25 19.2426 6.25736 20.25 7.5 20.25H18.5C18.6381 20.25 18.75 20.1381 18.75 20V15.75Z"
      fill="currentColor"
    />
  </svg>
);

export const StackIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.1547 3.36026C12.4836 3.00999 11.5742 3.00459 10.8978 3.35396C8.30475 4.6931 5.95288 6.22853 3.89191 7.93154C3.5732 8.19489 3.34702 8.57758 3.35088 9.02802C3.35472 9.47662 3.5855 9.85419 3.90228 10.1132C5.94613 11.7843 8.29994 13.3212 10.8455 14.6497C11.5166 15 12.426 15.0054 13.1025 14.656C15.6954 13.3169 18.0473 11.7814 20.1083 10.0784C20.427 9.81511 20.6532 9.43242 20.6493 8.98197C20.6454 8.53338 20.4147 8.1558 20.0979 7.89679C18.054 6.22562 15.7002 4.6888 13.1547 3.36026ZM11.5861 4.68671C11.8257 4.56294 12.2194 4.56411 12.4607 4.69004C14.89 5.95796 17.1284 7.41666 19.0677 8.99223C17.1169 10.5931 14.8851 12.0471 12.4142 13.3232C12.1745 13.447 11.7808 13.4458 11.5395 13.3199C9.11013 12.052 6.87172 10.5933 4.93249 9.01777C6.8833 7.41684 9.11505 5.96285 11.5861 4.68671Z"
      fill="currentColor"
    />
    <path
      d="M21.1971 12.6982C21.4165 13.0495 21.3095 13.5122 20.9581 13.7316L14.8509 17.5445C14.0222 18.0619 12.994 18.3011 12 18.3011C11.0061 18.3012 9.97782 18.0621 9.14896 17.5448L3.10863 13.7751C2.75723 13.5558 2.65015 13.0931 2.86946 12.7417C3.08876 12.3903 3.55141 12.2832 3.9028 12.5025L9.94313 16.2723C10.4873 16.6119 11.2265 16.8012 11.9999 16.8011C12.7734 16.8011 13.5125 16.6117 14.0565 16.2721L20.1638 12.4592C20.5151 12.2398 20.9778 12.3468 21.1971 12.6982Z"
      fill="currentColor"
    />
    <path
      d="M21.1971 16.4526C21.4165 16.804 21.3095 17.2666 20.9582 17.486L15.6693 20.7889C14.6117 21.4494 13.2887 21.7601 12 21.7602C10.7113 21.7603 9.3882 21.4497 8.33042 20.7894L3.10855 17.5295C2.75718 17.3102 2.65016 16.8475 2.86951 16.4962C3.08885 16.1448 3.55151 16.0378 3.90288 16.2571L9.12474 19.5169C9.89776 19.9995 10.9317 20.2603 11.9999 20.2602C13.0681 20.2601 14.1019 19.9993 14.8748 19.5166L20.1637 16.2138C20.515 15.9944 20.9777 16.1013 21.1971 16.4526Z"
      fill="currentColor"
    />
  </svg>
);

export const UserIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.75 7.5C7.75 5.15279 9.65279 3.25 12 3.25C14.3472 3.25 16.25 5.15279 16.25 7.5C16.25 9.84721 14.3472 11.75 12 11.75C9.65279 11.75 7.75 9.84721 7.75 7.5ZM12 4.75C10.4812 4.75 9.25 5.98122 9.25 7.5C9.25 9.01878 10.4812 10.25 12 10.25C13.5188 10.25 14.75 9.01878 14.75 7.5C14.75 5.98122 13.5188 4.75 12 4.75Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8 14.75C6.75736 14.75 5.75 15.7574 5.75 17V18.1883C5.75 18.2064 5.76311 18.2218 5.78097 18.2247C9.89972 18.8972 14.1003 18.8972 18.219 18.2247C18.2369 18.2218 18.25 18.2064 18.25 18.1883V17C18.25 15.7574 17.2426 14.75 16 14.75H15.6591C15.6328 14.75 15.6066 14.7542 15.5815 14.7623L14.716 15.045C12.9512 15.6212 11.0488 15.6212 9.28398 15.045L8.41847 14.7623C8.39342 14.7542 8.36722 14.75 8.34087 14.75H8ZM4.25 17C4.25 14.9289 5.92893 13.25 8 13.25H8.34087C8.52536 13.25 8.70869 13.2792 8.88407 13.3364L9.74959 13.6191C11.2119 14.0965 12.7881 14.0965 14.2504 13.6191L15.1159 13.3364C15.2913 13.2792 15.4746 13.25 15.6591 13.25H16C18.0711 13.25 19.75 14.9289 19.75 17V18.1883C19.75 18.9415 19.2041 19.5837 18.4607 19.7051C14.1819 20.4037 9.8181 20.4037 5.53927 19.7051C4.79588 19.5837 4.25 18.9415 4.25 18.1883V17Z"
      fill="currentColor"
    />
  </svg>
);

export const LockIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.62098 8.5971L3.30554 5.75816C3.2651 5.39418 3.2651 5.02684 3.30554 4.66286L3.32831 4.45796C3.56979 2.28457 5.2784 0.564922 7.45023 0.309412C7.81503 0.266492 8.18363 0.266492 8.54843 0.309412C10.7202 0.564922 12.4288 2.28458 12.6703 4.45797L12.693 4.66286C12.7335 5.02684 12.7335 5.39418 12.693 5.75815L12.3776 8.5971L13.0642 8.6519C14.1469 8.7384 15.031 9.5523 15.2066 10.6242C15.5726 12.8598 15.5726 15.1401 15.2066 17.3758C15.031 18.4477 14.1469 19.2616 13.0642 19.348L11.5681 19.4675C9.19273 19.6571 6.80603 19.6571 4.43061 19.4675L2.93448 19.348C1.85175 19.2616 0.967651 18.4477 0.792131 17.3758C0.426061 15.1401 0.426061 12.8598 0.792131 10.6242C0.967651 9.5523 1.85175 8.7384 2.93448 8.6519L3.62098 8.5971ZM7.62543 1.79914C7.87383 1.76992 8.12483 1.76992 8.37313 1.79914C9.85183 1.97309 11.015 3.1439 11.1795 4.62361L11.2022 4.82851C11.2304 5.08239 11.2304 5.33862 11.2022 5.59251L10.8811 8.483C8.96193 8.3594 7.03673 8.3594 5.11753 8.483L4.79637 5.59251C4.76816 5.33862 4.76816 5.08239 4.79637 4.82851L4.81913 4.62361C4.98355 3.1439 6.14683 1.97309 7.62543 1.79914ZM11.4487 10.0277C9.15283 9.8445 6.84593 9.8445 4.54998 10.0277L3.05385 10.1472C2.65892 10.1787 2.33644 10.4756 2.27242 10.8666C1.93263 12.9417 1.93263 15.0583 2.27242 17.1334C2.33644 17.5244 2.65892 17.8213 3.05385 17.8528L4.54998 17.9722C6.84593 18.1555 9.15283 18.1555 11.4487 17.9722L12.9448 17.8528C13.3398 17.8213 13.6622 17.5244 13.7263 17.1334C14.0661 15.0583 14.0661 12.9417 13.7263 10.8666C13.6622 10.4756 13.3398 10.1787 12.9448 10.1472L11.4487 10.0277Z"
      fill="currentColor"
    />
  </svg>
);

export const InfoIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className="w-5 h-5"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
    />
  </svg>
);

export const NeedHelpIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M8 9.49997C7.30964 9.49997 6.75 10.0596 6.75 10.75C6.75 11.4403 7.30964 12 8 12C8.69036 12 9.25 11.4403 9.25 10.75C9.25 10.0596 8.69036 9.49997 8 9.49997Z"
      fill="#FFFFFF"
    />
    <path
      d="M12 9.49997C11.3096 9.49997 10.75 10.0596 10.75 10.75C10.75 11.4403 11.3096 12 12 12C12.6904 12 13.25 11.4403 13.25 10.75C13.25 10.0596 12.6904 9.49997 12 9.49997Z"
      fill="#FFFFFF"
    />
    <path
      d="M14.75 10.75C14.75 10.0596 15.3096 9.49997 16 9.49997C16.6904 9.49997 17.25 10.0596 17.25 10.75C17.25 11.4403 16.6904 12 16 12C15.3096 12 14.75 11.4403 14.75 10.75Z"
      fill="#FFFFFF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.1007 4.59277C13.4065 4.36301 10.6983 4.34967 8.00194 4.5529L7.80871 4.56746C5.23741 4.76126 3.25 6.90409 3.25 9.48269V18C3.25 18.2639 3.38867 18.5083 3.61515 18.6437C3.84163 18.7791 4.12261 18.7855 4.35504 18.6606L8.26583 16.5589C8.44774 16.4611 8.65104 16.41 8.85756 16.41H17.834C18.9661 16.41 19.9362 15.6006 20.1392 14.4868C20.5505 12.2296 20.5829 9.91963 20.2353 7.65173L20.1329 6.9834C19.9464 5.76665 18.951 4.83584 17.7245 4.73124L16.1007 4.59277ZM8.11468 6.04865C10.731 5.85146 13.359 5.8644 15.9733 6.08734L17.597 6.22581C18.1334 6.27155 18.5686 6.67859 18.6502 7.21067L18.7526 7.879C19.075 9.98228 19.0449 12.1246 18.6635 14.218C18.5904 14.6187 18.2413 14.91 17.834 14.91H8.85756C8.40322 14.91 7.95596 15.0225 7.55575 15.2376L4.75 16.7455V9.48269C4.75 7.68878 6.13262 6.19804 7.92144 6.06322L8.11468 6.04865Z"
      fill="#FFFFFF"
    />
  </svg>
);

export const AlertTrianleIcon = (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.33329 6.33468C8.33329 6.24627 8.29817 6.16149 8.23566 6.09897C8.17315 6.03646 8.08836 6.00134 7.99996 6.00134C7.91155 6.00134 7.82677 6.03646 7.76426 6.09897C7.70174 6.16149 7.66663 6.24627 7.66663 6.33468V10.3347C7.66663 10.4231 7.70174 10.5079 7.76426 10.5704C7.82677 10.6329 7.91155 10.668 7.99996 10.668C8.08836 10.668 8.17315 10.6329 8.23566 10.5704C8.29817 10.5079 8.33329 10.4231 8.33329 10.3347V6.33468Z"
      fill="currentColor"
    />
    <path
      d="M7.99935 11.9907C8.18344 11.9907 8.33268 11.8414 8.33268 11.6573C8.33268 11.4732 8.18344 11.324 7.99935 11.324C7.81525 11.324 7.66602 11.4732 7.66602 11.6573C7.66602 11.8414 7.81525 11.9907 7.99935 11.9907Z"
      fill="currentColor"
    />
    <path
      d="M12.428 14.456H3.59002C3.30433 14.4559 3.02347 14.3824 2.77438 14.2424C2.5253 14.1025 2.31637 13.9009 2.16763 13.657C2.0189 13.4131 1.93536 13.1351 1.92504 12.8496C1.91472 12.5641 1.97796 12.2807 2.10869 12.0267L6.52802 3.44734C6.66826 3.17536 6.88067 2.94725 7.14196 2.78799C7.40326 2.62874 7.70335 2.54449 8.00935 2.54449C8.31536 2.54449 8.61545 2.62874 8.87675 2.78799C9.13804 2.94725 9.35045 3.17536 9.49069 3.44734L13.91 12.0267C14.0408 12.2807 14.104 12.5642 14.0937 12.8497C14.0833 13.1353 13.9997 13.4134 13.8509 13.6573C13.7021 13.9012 13.4931 14.1028 13.2439 14.2427C12.9947 14.3826 12.7138 14.456 12.428 14.456ZM8.00935 3.21C7.82522 3.20832 7.64429 3.25827 7.4871 3.35418C7.32991 3.4501 7.20274 3.58815 7.12002 3.75267L2.70135 12.332C2.62298 12.4844 2.58509 12.6544 2.59131 12.8256C2.59753 12.9969 2.64765 13.1637 2.73687 13.31C2.82608 13.4563 2.9514 13.5772 3.1008 13.6611C3.25019 13.7451 3.41866 13.7892 3.59002 13.7893H12.428C12.5994 13.7893 12.768 13.7453 12.9175 13.6614C13.067 13.5775 13.1924 13.4566 13.2817 13.3102C13.371 13.1639 13.4211 12.9971 13.4274 12.8258C13.4336 12.6545 13.3958 12.4844 13.3174 12.332L8.89869 3.75267C8.81597 3.58815 8.6888 3.4501 8.53161 3.35418C8.37442 3.25827 8.19349 3.20832 8.00935 3.21Z"
      fill="currentColor"
    />
  </svg>
);

export const PencilIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className="w-6 h-6"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
    />
  </svg>
);

export const DeleteIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    width="22"
    height="22"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
    />
  </svg>
);

export const PlusIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    width="22"
    height="40"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12 4.5v15m7.5-7.5h-15"
    />
  </svg>
);

export const NoDataIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    x="0px"
    y="0px"
    width="70"
    height="90"
  >
    <path d="M39.22,29.57c-2.09-2.09-4.88-3.25-7.85-3.25s-5.76,1.15-7.85,3.25s-3.25,4.88-3.25,7.85c0,2.97,1.15,5.76,3.25,7.85  c2.09,2.09,4.88,3.25,7.85,3.25c2.97,0,5.76-1.15,7.85-3.25C43.54,40.94,43.54,33.9,39.22,29.57z M38.16,44.21  c-1.81,1.81-4.22,2.81-6.79,2.81c-2.57,0-4.98-1-6.79-2.81s-2.81-4.22-2.81-6.79c0-2.57,1-4.98,2.81-6.79s4.22-2.81,6.79-2.81  s4.98,1,6.79,2.81C41.9,34.37,41.9,40.46,38.16,44.21z" />
    <path d="M57.54,58l-9.24-9.24c-0.86-0.86-2.05-1.27-3.25-1.13l-1.33-1.33c4.28-5.95,3.76-14.3-1.58-19.65  c-5.94-5.94-15.6-5.94-21.54,0c-5.94,5.94-5.94,15.6,0,21.54c2.97,2.97,6.87,4.45,10.77,4.45c3.12,0,6.24-0.97,8.88-2.87l1.33,1.33  c-0.14,1.2,0.27,2.39,1.13,3.24l9.24,9.25c0.77,0.77,1.78,1.16,2.8,1.16c1.01,0,2.03-0.39,2.8-1.16c0.75-0.75,1.16-1.74,1.16-2.8  C58.7,59.74,58.28,58.74,57.54,58z M21.65,47.13c-5.35-5.35-5.35-14.07,0-19.42c2.68-2.68,6.19-4.02,9.71-4.02s7.03,1.34,9.71,4.02  c5.35,5.35,5.35,14.07,0,19.42C35.72,52.48,27.01,52.48,21.65,47.13z M56.48,62.53c-0.96,0.96-2.52,0.96-3.47,0l-9.24-9.24  c-0.59-0.59-0.84-1.45-0.65-2.28c0.06-0.25-0.02-0.51-0.2-0.69l-1.47-1.47c0.01-0.01,0.02-0.03,0.03-0.05  c0.22-0.2,0.46-0.39,0.67-0.61c0.22-0.22,0.41-0.45,0.61-0.68c0.01-0.01,0.03-0.01,0.04-0.02l1.47,1.47  c0.18,0.18,0.44,0.26,0.69,0.2c0.83-0.19,1.68,0.06,2.28,0.65l9.24,9.25c0.46,0.46,0.72,1.08,0.72,1.74  C57.2,61.45,56.94,62.07,56.48,62.53z" />
    <path d="M35.72,33.06c-0.29-0.29-0.77-0.29-1.06,0l-3.29,3.29l-3.29-3.29c-0.29-0.29-0.77-0.29-1.06,0c-0.29,0.29-0.29,0.77,0,1.06  l3.29,3.3l-3.29,3.29c-0.29,0.29-0.29,0.77,0,1.06c0.15,0.15,0.34,0.22,0.53,0.22s0.38-0.07,0.53-0.22l3.29-3.29l3.29,3.29  c0.15,0.15,0.34,0.22,0.53,0.22s0.38-0.07,0.53-0.22c0.29-0.29,0.29-0.77,0-1.06l-3.29-3.29l3.29-3.3  C36.01,33.83,36.01,33.36,35.72,33.06z" />
    <path d="M23.92,7.2h18.95c0.41,0,0.75-0.34,0.75-0.75S43.28,5.7,42.87,5.7H23.92c-0.41,0-0.75,0.34-0.75,0.75S23.51,7.2,23.92,7.2z" />
    <path d="M42.88,9.67H23.91c-0.41,0-0.75,0.34-0.75,0.75s0.34,0.75,0.75,0.75h18.97c0.41,0,0.75-0.34,0.75-0.75  S43.29,9.67,42.88,9.67z" />
    <path d="M42.88,13.68h-9.96c-0.41,0-0.75,0.34-0.75,0.75s0.34,0.75,0.75,0.75h9.96c0.41,0,0.75-0.34,0.75-0.75  S43.29,13.68,42.88,13.68z" />
    <path d="M42.88,17.68h-9.96c-0.41,0-0.75,0.34-0.75,0.75c0,0.41,0.34,0.75,0.75,0.75h9.96c0.41,0,0.75-0.34,0.75-0.75  C43.63,18.02,43.29,17.68,42.88,17.68z" />
    <path d="M45.26,59.34H12.79v-2.48h28.49c0.41,0,0.75-0.34,0.75-0.75s-0.34-0.75-0.75-0.75H8.8V12.98h9.48  c0.41,0,0.75-0.34,0.75-0.75V2.75h28.72v43.4c0,0.41,0.34,0.75,0.75,0.75s0.75-0.34,0.75-0.75V6.73h2.48v43.03  c0,0.41,0.34,0.75,0.75,0.75s0.75-0.34,0.75-0.75V5.98c0-0.41-0.34-0.75-0.75-0.75h-3.23V2c0-0.41-0.34-0.75-0.75-0.75H18.29  c-0.2,0-0.39,0.08-0.53,0.22c0,0,0,0,0,0L7.52,11.7c0,0,0,0,0,0c-0.14,0.14-0.22,0.33-0.22,0.53v43.87c0,0.41,0.34,0.75,0.75,0.75  h3.23v3.23c0,0.41,0.34,0.75,0.75,0.75h33.22c0.41,0,0.75-0.34,0.75-0.75S45.67,59.34,45.26,59.34z M9.86,11.48l7.67-7.67v7.67H9.86  z" />
  </svg>
);

export const CircularTickIcon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.01 0C4.48 0 0 4.47776 0 9.995C0 15.5122 4.48 20 10 20C15.52 20 20 15.5222 20 9.995C20 4.46777 15.53 0 10.01 0Z"
      fill="#A2F0EC"
    />
    <path
      d="M6 10.2623L9.38069 12.9763L14.3333 6.66666"
      stroke="#006D88"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const ArrowDownIcon = (
  <svg
    width="13"
    height="8"
    viewBox="0 0 13 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.3737 0.29289C12.7643 0.68341 12.7643 1.31658 12.3737 1.70706L7.0404 7.0404C6.64986 7.43093 6.0168 7.43093 5.62626 7.0404L0.29289 1.70706C-0.09763 1.31658 -0.09763 0.68341 0.29289 0.29289C0.68341 -0.09763 1.31658 -0.09763 1.7071 0.29289L6.33333 4.91906L10.9596 0.29289C11.3501 -0.09763 11.9832 -0.09763 12.3737 0.29289Z"
      fill="#163545"
    />
  </svg>
);

export const ArrowUpIcon = (
  <svg
    width="13"
    height="8"
    viewBox="0 0 13 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.626269 7.70711C0.235737 7.31659 0.235737 6.68342 0.626269 6.29294L5.9596 0.959604C6.35014 0.56907 6.9832 0.56907 7.37374 0.959604L12.7071 6.29294C13.0976 6.68342 13.0976 7.31659 12.7071 7.70711C12.3166 8.09763 11.6834 8.09763 11.2929 7.70711L6.66667 3.08094L2.0404 7.70711C1.64987 8.09763 1.0168 8.09763 0.626269 7.70711Z"
      fill="#163545"
    />
  </svg>
);

export const LockIconNew = (
  <svg
    width="18"
    height="22"
    viewBox="0 0 18 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.5 9.5V5.75C13.5 3.26472 11.4853 1.25 9 1.25C6.51472 1.25 4.5 3.26472 4.5 5.75V9.5M3.75 20.75H14.25C15.4926 20.75 16.5 19.7426 16.5 18.5V11.75C16.5 10.5074 15.4926 9.5 14.25 9.5H3.75C2.50736 9.5 1.5 10.5074 1.5 11.75V18.5C1.5 19.7426 2.50736 20.75 3.75 20.75Z"
      stroke="#555555"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const TrestleIcon = (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.01971 0C4.03679 0 0 4.03604 0 9.00901C0 13.982 4.03679 18.027 9.0107 18.027C13.9846 18.027 18.0214 13.991 18.0214 9.00901C18.0214 4.02703 13.9936 0 9.01971 0Z"
      fill="#44DAD3"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M38.9892 29.973C34.0153 29.973 29.9785 34.0091 29.9785 38.982C29.9785 43.955 34.0153 48 38.9892 48C43.9631 48 47.9999 43.964 47.9999 38.982C47.9999 34 43.9631 29.973 38.9892 29.973Z"
      fill="#44DAD3"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M48 0C34.7453 0 24.0045 10.7457 24.0045 23.9955H0V47.991C13.2547 47.991 24.0045 37.2453 24.0045 23.9955H48V0Z"
      fill="#0494B4"
    />
  </svg>
);

export const DotIcon = (
  <svg
    width="10"
    height="11"
    viewBox="0 0 10 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="5" cy="5.5" r="5" fill="#006D88" />
  </svg>
);

export const CheckIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 12.3935L11.071 16.4645L18.5 7"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const WalletCloseIcon = (
  <svg
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.273071 2.62524C1.06638 1.92494 2.10851 1.5 3.24989 1.5H16.7499C17.8913 1.5 18.9334 1.92494 19.7267 2.62524C19.5423 1.14526 18.2798 0 16.7499 0H3.24989C1.71995 0 0.457504 1.14525 0.273071 2.62524Z"
      fill="white"
    />
    <path
      d="M0.273071 5.62524C1.06638 4.92494 2.10851 4.5 3.24989 4.5H16.7499C17.8913 4.5 18.9334 4.92494 19.7267 5.62524C19.5423 4.14526 18.2798 3 16.7499 3H3.24989C1.71995 3 0.457504 4.14525 0.273071 5.62524Z"
      fill="white"
    />
    <path
      d="M3.25 6C1.59315 6 0.25 7.34315 0.25 9V15C0.25 16.6569 1.59315 18 3.25 18H16.75C18.4069 18 19.75 16.6569 19.75 15V9C19.75 7.34315 18.4069 6 16.75 6H13C12.5858 6 12.25 6.33579 12.25 6.75C12.25 7.99264 11.2426 9 10 9C8.75736 9 7.75 7.99264 7.75 6.75C7.75 6.33579 7.41421 6 7 6H3.25Z"
      fill="white"
    />
  </svg>
);

export const WalletOpenIcon = (
  <svg
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.273071 2.62524C1.06638 1.92494 2.10851 1.5 3.24989 1.5H16.7499C17.8913 1.5 18.9334 1.92494 19.7267 2.62524C19.5423 1.14526 18.2798 0 16.7499 0H3.24989C1.71995 0 0.457504 1.14525 0.273071 2.62524Z"
      fill="#163545"
    />
    <path
      d="M0.273071 5.62524C1.06638 4.92494 2.10851 4.5 3.24989 4.5H16.7499C17.8913 4.5 18.9334 4.92494 19.7267 5.62524C19.5423 4.14526 18.2798 3 16.7499 3H3.24989C1.71995 3 0.457504 4.14525 0.273071 5.62524Z"
      fill="#163545"
    />
    <path
      d="M3.25 6C1.59315 6 0.25 7.34315 0.25 9V15C0.25 16.6569 1.59315 18 3.25 18H16.75C18.4069 18 19.75 16.6569 19.75 15V9C19.75 7.34315 18.4069 6 16.75 6H13C12.5858 6 12.25 6.33579 12.25 6.75C12.25 7.99264 11.2426 9 10 9C8.75736 9 7.75 7.99264 7.75 6.75C7.75 6.33579 7.41421 6 7 6H3.25Z"
      fill="#163545"
    />
  </svg>
);

export const WalletWarningOpenIcon = (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.27307 13.6252C5.06638 12.9249 6.10851 12.5 7.24989 12.5H20.7499C21.8913 12.5 22.9334 12.9249 23.7267 13.6252C23.5423 12.1453 22.2798 11 20.7499 11H7.24989C5.71995 11 4.4575 12.1453 4.27307 13.6252Z"
      fill="white"
    />
    <path
      d="M4.27307 16.6252C5.06638 15.9249 6.10851 15.5 7.24989 15.5H20.7499C21.8913 15.5 22.9334 15.9249 23.7267 16.6252C23.5423 15.1453 22.2798 14 20.7499 14H7.24989C5.71995 14 4.4575 15.1453 4.27307 16.6252Z"
      fill="white"
    />
    <path
      d="M7.25 17C5.59315 17 4.25 18.3431 4.25 20V26C4.25 27.6569 5.59315 29 7.25 29H20.75C22.4069 29 23.75 27.6569 23.75 26V20C23.75 18.3431 22.4069 17 20.75 17H17C16.5858 17 16.25 17.3358 16.25 17.75C16.25 18.9926 15.2426 20 14 20C12.7574 20 11.75 18.9926 11.75 17.75C11.75 17.3358 11.4142 17 11 17H7.25Z"
      fill="white"
    />
    <circle
      cx="24"
      cy="10"
      r="9"
      fill="#F78331"
      stroke="#163545"
      stroke-width="2"
    />
    <path
      d="M23.3286 11.348L23.1606 5.6H24.8046L24.6246 11.348H23.3286ZM24.0126 14.084C23.7246 14.084 23.4886 13.996 23.3046 13.82C23.1286 13.644 23.0406 13.432 23.0406 13.184C23.0406 12.928 23.1286 12.712 23.3046 12.536C23.4886 12.36 23.7246 12.272 24.0126 12.272C24.2846 12.272 24.5086 12.36 24.6846 12.536C24.8686 12.712 24.9606 12.928 24.9606 13.184C24.9606 13.432 24.8686 13.644 24.6846 13.82C24.5086 13.996 24.2846 14.084 24.0126 14.084Z"
      fill="white"
    />
    <path
      d="M4.27307 13.6252C5.06638 12.9249 6.10851 12.5 7.24989 12.5H20.7499C21.8913 12.5 22.9334 12.9249 23.7267 13.6252C23.5423 12.1453 22.2798 11 20.7499 11H7.24989C5.71995 11 4.4575 12.1453 4.27307 13.6252Z"
      fill="#00485A"
    />
    <path
      d="M4.27307 16.6252C5.06638 15.9249 6.10851 15.5 7.24989 15.5H20.7499C21.8913 15.5 22.9334 15.9249 23.7267 16.6252C23.5423 15.1453 22.2798 14 20.7499 14H7.24989C5.71995 14 4.4575 15.1453 4.27307 16.6252Z"
      fill="#00485A"
    />
    <path
      d="M7.25 17C5.59315 17 4.25 18.3431 4.25 20V26C4.25 27.6569 5.59315 29 7.25 29H20.75C22.4069 29 23.75 27.6569 23.75 26V20C23.75 18.3431 22.4069 17 20.75 17H17C16.5858 17 16.25 17.3358 16.25 17.75C16.25 18.9926 15.2426 20 14 20C12.7574 20 11.75 18.9926 11.75 17.75C11.75 17.3358 11.4142 17 11 17H7.25Z"
      fill="#00485A"
    />
    <circle
      cx="24"
      cy="10"
      r="9"
      fill="#F78331"
      stroke="#F5F5F5"
      stroke-width="2"
    />
    <path
      d="M23.3286 11.348L23.1606 5.6H24.8046L24.6246 11.348H23.3286ZM24.0126 14.084C23.7246 14.084 23.4886 13.996 23.3046 13.82C23.1286 13.644 23.0406 13.432 23.0406 13.184C23.0406 12.928 23.1286 12.712 23.3046 12.536C23.4886 12.36 23.7246 12.272 24.0126 12.272C24.2846 12.272 24.5086 12.36 24.6846 12.536C24.8686 12.712 24.9606 12.928 24.9606 13.184C24.9606 13.432 24.8686 13.644 24.6846 13.82C24.5086 13.996 24.2846 14.084 24.0126 14.084Z"
      fill="white"
    />
  </svg>
);

export const WalletWarningCloseIcon = (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.27307 13.6252C5.06638 12.9249 6.10851 12.5 7.24989 12.5H20.7499C21.8913 12.5 22.9334 12.9249 23.7267 13.6252C23.5423 12.1453 22.2798 11 20.7499 11H7.24989C5.71995 11 4.4575 12.1453 4.27307 13.6252Z"
      fill="white"
    />
    <path
      d="M4.27307 16.6252C5.06638 15.9249 6.10851 15.5 7.24989 15.5H20.7499C21.8913 15.5 22.9334 15.9249 23.7267 16.6252C23.5423 15.1453 22.2798 14 20.7499 14H7.24989C5.71995 14 4.4575 15.1453 4.27307 16.6252Z"
      fill="white"
    />
    <path
      d="M7.25 17C5.59315 17 4.25 18.3431 4.25 20V26C4.25 27.6569 5.59315 29 7.25 29H20.75C22.4069 29 23.75 27.6569 23.75 26V20C23.75 18.3431 22.4069 17 20.75 17H17C16.5858 17 16.25 17.3358 16.25 17.75C16.25 18.9926 15.2426 20 14 20C12.7574 20 11.75 18.9926 11.75 17.75C11.75 17.3358 11.4142 17 11 17H7.25Z"
      fill="white"
    />
    <circle
      cx="24"
      cy="10"
      r="9"
      fill="#F78331"
      stroke="#163545"
      stroke-width="2"
    />
    <path
      d="M23.3286 11.348L23.1606 5.6H24.8046L24.6246 11.348H23.3286ZM24.0126 14.084C23.7246 14.084 23.4886 13.996 23.3046 13.82C23.1286 13.644 23.0406 13.432 23.0406 13.184C23.0406 12.928 23.1286 12.712 23.3046 12.536C23.4886 12.36 23.7246 12.272 24.0126 12.272C24.2846 12.272 24.5086 12.36 24.6846 12.536C24.8686 12.712 24.9606 12.928 24.9606 13.184C24.9606 13.432 24.8686 13.644 24.6846 13.82C24.5086 13.996 24.2846 14.084 24.0126 14.084Z"
      fill="white"
    />
  </svg>
);

export const DollarIcon = (
  <svg
    width="11"
    height="18"
    viewBox="0 0 11 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.12 17.74V16.2C3.81333 16.0533 2.78 15.5867 2.02 14.8C1.26 14.0133 0.88 13.0133 0.88 11.8H2.64C2.64 12.5067 2.84667 13.14 3.26 13.7C3.68667 14.2467 4.30667 14.6 5.12 14.76V9.34C5.02667 9.31333 4.92667 9.28667 4.82 9.26C4.72667 9.22 4.63333 9.18667 4.54 9.16C3.46 8.78667 2.66 8.32667 2.14 7.78C1.62 7.22 1.36 6.49333 1.36 5.6C1.34667 4.56 1.67333 3.69333 2.34 3C3.02 2.30667 3.94667 1.90667 5.12 1.8V0.18H6.5V1.8C7.62 1.93333 8.51333 2.35333 9.18 3.06C9.84667 3.76667 10.1867 4.62667 10.2 5.64H8.44C8.44 5.14667 8.28 4.67333 7.96 4.22C7.64 3.75333 7.15333 3.44 6.5 3.28V8.12C6.60667 8.14667 6.71333 8.18 6.82 8.22C6.92667 8.26 7.04 8.29333 7.16 8.32C7.82667 8.56 8.42667 8.84 8.96 9.16C9.49333 9.48 9.90667 9.89333 10.2 10.4C10.5067 10.9067 10.66 11.5533 10.66 12.34C10.66 12.9667 10.5067 13.56 10.2 14.12C9.89333 14.68 9.42667 15.1533 8.8 15.54C8.18667 15.9267 7.42 16.1533 6.5 16.22V17.74H5.12ZM3.1 5.44C3.1 6.05333 3.28 6.52 3.64 6.84C4 7.14667 4.49333 7.41333 5.12 7.64V3.24C4.52 3.33333 4.03333 3.57333 3.66 3.96C3.28667 4.33333 3.1 4.82667 3.1 5.44ZM8.92 12.44C8.92 11.6667 8.69333 11.0933 8.24 10.72C7.78667 10.3467 7.20667 10.04 6.5 9.8V14.8C7.27333 14.7067 7.86667 14.44 8.28 14C8.70667 13.56 8.92 13.04 8.92 12.44Z"
      fill="#555555"
    />
  </svg>
);

export const warningCircleIcon = (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="8" cy="8.5" r="8" fill="#F78331" />
    <path
      d="M7.32861 9.848L7.16061 4.1H8.80461L8.62461 9.848H7.32861ZM8.01261 12.584C7.72461 12.584 7.48861 12.496 7.30461 12.32C7.12861 12.144 7.04061 11.932 7.04061 11.684C7.04061 11.428 7.12861 11.212 7.30461 11.036C7.48861 10.86 7.72461 10.772 8.01261 10.772C8.28461 10.772 8.50861 10.86 8.68461 11.036C8.86861 11.212 8.96061 11.428 8.96061 11.684C8.96061 11.932 8.86861 12.144 8.68461 12.32C8.50861 12.496 8.28461 12.584 8.01261 12.584Z"
      fill="white"
    />
  </svg>
);

export const TrestleLogo = (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.00657 0.5C1.3456 0.5 0 1.84535 0 3.503C0 5.16066 1.3456 6.50901 3.00357 6.50901C4.66153 6.50901 6.00713 5.16366 6.00713 3.503C6.00713 1.84234 4.66454 0.5 3.00657 0.5Z"
      fill="#44DAD3"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.9967 10.491C11.3388 10.491 9.99316 11.8363 9.99316 13.494C9.99316 15.1516 11.3388 16.5 12.9967 16.5C14.6547 16.5 16.0003 15.1546 16.0003 13.494C16.0003 11.8333 14.6547 10.491 12.9967 10.491Z"
      fill="#44DAD3"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16 0.5C11.5818 0.5 8.0015 4.08191 8.0015 8.4985H0V16.497C4.41825 16.497 8.0015 12.9151 8.0015 8.4985H16V0.5Z"
      fill="#0494B4"
    />
  </svg>
);

export const ExpandIcon = (
  <svg
    width="13"
    height="8"
    viewBox="0 0 13 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.3737 0.29289C12.7643 0.68341 12.7643 1.31658 12.3737 1.70706L7.0404 7.0404C6.64986 7.43093 6.0168 7.43093 5.62626 7.0404L0.29289 1.70706C-0.09763 1.31658 -0.09763 0.68341 0.29289 0.29289C0.68341 -0.09763 1.31658 -0.09763 1.7071 0.29289L6.33333 4.91906L10.9596 0.29289C11.3501 -0.09763 11.9832 -0.09763 12.3737 0.29289Z"
      fill="#00485A"
    />
  </svg>
);

export const BatchUploadUpgradeIcon = (
  <svg
    width="200"
    height="200"
    viewBox="0 0 200 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M32 80C32 75.5817 35.5817 72 40 72H144C148.418 72 152 75.5817 152 80V168C152 172.418 148.418 176 144 176H40C35.5817 176 32 172.418 32 168V80Z"
      fill="#F5F5F5"
    />
    <path
      d="M33 80C33 76.134 36.134 73 40 73H144C147.866 73 151 76.134 151 80V168C151 171.866 147.866 175 144 175H40C36.134 175 33 171.866 33 168V80Z"
      stroke="#555555"
      stroke-opacity="0.1"
      stroke-width="2"
    />
    <path
      d="M82.4307 8.57438C84.6398 4.74804 89.5325 3.43704 93.3589 5.64618L162.641 45.6462C166.467 47.8553 167.778 52.748 165.569 56.5744L102.831 165.24C100.186 169.821 93.9019 170.62 90.1951 166.845L52.7228 128.688C51.2537 127.192 50.4307 125.179 50.4307 123.082L50.4307 66.1436C50.4307 64.7393 50.8003 63.3598 51.5025 62.1436L82.4307 8.57438Z"
      fill="white"
    />
    <path
      d="M83.2967 9.07438C85.2297 5.72633 89.5108 4.57921 92.8589 6.5122L162.141 46.5122C165.489 48.4452 166.636 52.7263 164.703 56.0744L101.965 164.74C99.6506 168.748 94.152 169.447 90.9086 166.144L53.4363 127.987C52.1509 126.678 51.4307 124.917 51.4307 123.082L51.4307 66.1436C51.4307 64.9148 51.7541 63.7077 52.3685 62.6436L83.2967 9.07438Z"
      stroke="#555555"
      stroke-opacity="0.1"
      stroke-width="2"
    />
    <path
      d="M62.2 13.7574C63.3435 9.48971 67.7302 6.95705 71.9979 8.10058L149.272 28.8061C153.54 29.9496 156.072 34.3363 154.929 38.6041L121.926 161.771C120.735 166.219 116.044 168.745 111.675 167.292L50.5294 146.962C47.2342 145.866 45.0221 142.77 45.0538 139.297L45.6263 76.5927C45.6324 75.9181 45.7239 75.2469 45.8985 74.5952L62.2 13.7574Z"
      fill="white"
    />
    <path
      d="M63.1659 14.0163C64.1665 10.282 68.0048 8.06591 71.7391 9.06651L149.013 29.772C152.747 30.7726 154.964 34.611 153.963 38.3452L120.96 161.512C119.918 165.404 115.813 167.614 111.99 166.343L50.8449 146.013C47.9616 145.054 46.026 142.345 46.0537 139.306L46.6262 76.6019C46.6316 76.0115 46.7117 75.4243 46.8644 74.854L63.1659 14.0163Z"
      stroke="#555555"
      stroke-opacity="0.1"
      stroke-width="2"
    />
    <path
      d="M44 24C44 19.5817 47.5817 16 52 16H132C136.418 16 140 19.5817 140 24V152C140 156.418 136.418 160 132 160H52C47.5817 160 44 156.418 44 152V24Z"
      fill="white"
    />
    <path
      d="M45 24C45 20.134 48.134 17 52 17H132C135.866 17 139 20.134 139 24V152C139 155.866 135.866 159 132 159H52C48.134 159 45 155.866 45 152V24Z"
      stroke="#555555"
      stroke-opacity="0.1"
      stroke-width="2"
    />
    <path
      d="M14.4851 105.94C13.2228 100.891 17.0416 96 22.2462 96H161.754C166.958 96 170.777 100.891 169.515 105.94L153.515 169.94C152.625 173.502 149.425 176 145.754 176H38.2462C34.5753 176 31.3754 173.502 30.4851 169.94L14.4851 105.94Z"
      fill="#F5F5F5"
    />
    <path
      d="M15.4552 105.698C14.3507 101.28 17.6922 97 22.2462 97H161.754C166.308 97 169.649 101.28 168.545 105.698L152.545 169.698C151.766 172.814 148.966 175 145.754 175H38.2462C35.0341 175 32.2343 172.814 31.4552 169.698L15.4552 105.698Z"
      stroke="#555555"
      stroke-opacity="0.1"
      stroke-width="2"
    />
    <circle cx="152" cy="174" r="26" fill="#C0E4EC" />
    <rect x="150" y="160" width="4" height="28" rx="2" fill="white" />
    <rect
      x="166"
      y="172"
      width="4"
      height="28"
      rx="2"
      transform="rotate(90 166 172)"
      fill="white"
    />
  </svg>
);
export const HubspotIntegrationIcon = (
  <svg width="200" height="200" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_70_261)">
<circle cx="48" cy="64" r="45" fill="white" stroke="#DDDDDD" stroke-width="6"/>
<circle cx="152" cy="136" r="45" fill="white" stroke="#DDDDDD" stroke-width="6"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M138.268 114C133.7 114 130 117.7 130 122.258C130 126.817 133.7 130.525 138.26 130.525C142.819 130.525 146.52 126.825 146.52 122.258C146.52 117.691 142.827 114 138.268 114Z" fill="#44DAD3"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M165.74 141.475C161.181 141.475 157.48 145.175 157.48 149.733C157.48 154.292 161.181 158 165.74 158C170.3 158 174 154.3 174 149.733C174 145.167 170.3 141.475 165.74 141.475Z" fill="#44DAD3"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M174 114C161.85 114 152.004 123.85 152.004 135.996H130V157.992C142.15 157.992 152.004 148.141 152.004 135.996H174V114Z" fill="#0494B4"/>
<path d="M49.1949 59.3253C46.5522 61.8205 44.8994 65.3581 44.8994 69.2852C44.8994 72.3702 45.9206 75.2127 47.6368 77.4977L42.4251 82.7406C41.9619 82.5721 41.467 82.4775 40.951 82.4775C39.8141 82.4775 38.7507 82.9198 37.9505 83.72C37.1503 84.52 36.708 85.5937 36.708 86.7312C36.708 87.868 37.1502 88.9314 37.9505 89.7318C38.7507 90.5317 39.8246 90.9529 40.951 90.9529C42.0881 90.9529 43.1515 90.5421 43.9622 89.7318C44.7624 88.9314 45.2046 87.8575 45.2046 86.7312C45.2046 86.2889 45.1415 85.8677 45.0046 85.457L50.269 80.1716C52.5852 81.9509 55.4699 83.0039 58.6179 83.0039C66.1881 83.0039 72.305 76.8657 72.305 69.2957C72.305 62.4311 67.2831 56.7457 60.7026 55.7455V49.26C62.5452 48.4807 63.6716 46.7541 63.6716 44.743C63.6716 41.9951 61.4712 39.7 58.7231 39.7C55.9753 39.7 53.8064 41.9951 53.8064 44.743C53.8064 46.7541 54.9329 48.4807 56.7753 49.26V55.7034C55.1751 55.9245 53.659 56.4087 52.2798 57.1353C49.374 54.9347 39.9089 48.1122 34.4971 43.9955C34.6234 43.5323 34.7078 43.048 34.7078 42.5427C34.7078 39.5105 32.2336 37.0469 29.1908 37.0469C26.1586 37.0469 23.6949 39.5105 23.6949 42.5427C23.6949 45.5855 26.1586 48.0491 29.1908 48.0491C30.2226 48.0491 31.1806 47.7437 32.0124 47.249L49.1949 59.3253ZM58.6179 76.5502C54.6067 76.5502 51.3533 73.2967 51.3533 69.2853C51.3533 65.2741 54.6067 62.0208 58.6179 62.0208C62.6292 62.0208 65.8826 65.2741 65.8826 69.2853C65.8827 73.2967 62.6293 76.5502 58.6179 76.5502Z" fill="#F78331"/>
<g clip-path="url(#clip1_70_261)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M78.5701 32.4227C79.4157 33.8874 81.2887 34.3893 82.7535 33.5436C97.4012 25.0868 116.131 30.1055 124.588 44.7532C127.38 49.5893 128.701 54.8615 128.697 60.0627L128.693 71.0492L119.503 61.8592C118.307 60.6633 116.368 60.6633 115.172 61.8593C113.976 63.0552 113.976 64.9943 115.172 66.1903L129.588 80.6058C130.784 81.8018 132.723 81.8018 133.919 80.6058L148.334 66.1903C149.53 64.9943 149.53 63.0552 148.334 61.8593C147.138 60.6633 145.199 60.6633 144.003 61.8593L134.818 71.0443L134.822 60.0684C134.827 53.819 133.237 47.4843 129.892 41.6907C119.744 24.1134 97.2683 18.091 79.691 28.2392C78.2262 29.0849 77.7244 30.9579 78.5701 32.4227Z" fill="#DDDDDD"/>
</g>
<g clip-path="url(#clip2_70_261)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M121.43 167.577C120.584 166.113 118.711 165.611 117.246 166.456C102.599 174.913 83.8688 169.895 75.412 155.247C72.6198 150.411 71.2985 145.139 71.3034 139.937L71.3069 128.951L80.4969 138.141C81.6928 139.337 83.6319 139.337 84.8279 138.141C86.0239 136.945 86.0239 135.006 84.8279 133.81L70.4124 119.394C69.2164 118.198 67.2773 118.198 66.0813 119.394L51.6658 133.81C50.4698 135.006 50.4698 136.945 51.6658 138.141C52.8618 139.337 54.8009 139.337 55.9969 138.141L65.1819 128.956L65.1784 139.932C65.1726 146.181 66.7626 152.516 70.1075 158.309C80.2558 175.887 102.732 181.909 120.309 171.761C121.774 170.915 122.276 169.042 121.43 167.577Z" fill="#DDDDDD"/>
</g>
<circle cx="124" cy="34" r="20" fill="#AAAAAA"/>
<path d="M131.5 36.25V33.625C131.5 31.761 129.989 30.25 128.125 30.25H126.625C126.004 30.25 125.5 29.7463 125.5 29.125V27.625C125.5 25.761 123.989 24.25 122.125 24.25H120.25M120.25 37H127.75M120.25 40H124M122.5 24.25H117.625C117.004 24.25 116.5 24.7537 116.5 25.375V42.625C116.5 43.2463 117.004 43.75 117.625 43.75H130.375C130.996 43.75 131.5 43.2463 131.5 42.625V33.25C131.5 28.2794 127.471 24.25 122.5 24.25Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<circle cx="76" cy="166" r="20" fill="#AAAAAA"/>
<path d="M67 170.5L67 172.75C67 173.993 68.0074 175 69.25 175L82.75 175C83.9926 175 85 173.993 85 172.75V170.5M80.5 166L76 170.5M76 170.5L71.5 166M76 170.5L76 157" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_70_261">
<rect width="200" height="200" fill="white"/>
</clipPath>
<clipPath id="clip1_70_261">
<rect width="98" height="98" fill="white" transform="translate(98.0647 129.361) rotate(-135)"/>
</clipPath>
<clipPath id="clip2_70_261">
<rect width="98" height="98" fill="white" transform="translate(101.935 70.6388) rotate(45)"/>
</clipPath>
</defs>
</svg>
);

export const HubspotContactVerificationIcon = (
  <svg width="200" height="200" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8 37H184V173C184 175.209 182.209 177 180 177H12C9.79086 177 8 175.209 8 173V37Z" fill="white"/>
<path d="M9 38H183V173C183 174.657 181.657 176 180 176H12C10.3431 176 9 174.657 9 173V38Z" stroke="#555555" stroke-opacity="0.2" stroke-width="2"/>
<path d="M36.7966 61.8835C35.0348 63.547 33.933 65.9054 33.933 68.5234C33.933 70.5802 34.6137 72.4752 35.7579 73.9984L32.2834 77.4938C31.9746 77.3814 31.6447 77.3184 31.3007 77.3184C30.5427 77.3184 29.8338 77.6132 29.3003 78.1466C28.7669 78.68 28.472 79.3958 28.472 80.1541C28.472 80.912 28.7668 81.6209 29.3003 82.1545C29.8338 82.6878 30.5498 82.9686 31.3007 82.9686C32.0587 82.9686 32.7676 82.6948 33.3081 82.1545C33.8416 81.6209 34.1364 80.905 34.1364 80.1541C34.1364 79.8593 34.0944 79.5784 34.003 79.3047L37.5126 75.7811C39.0568 76.9673 40.9799 77.6693 43.0786 77.6693C48.1254 77.6693 52.2034 73.5771 52.2034 68.5305C52.2034 63.9541 48.8554 60.1638 44.4684 59.497V55.1734C45.6968 54.6538 46.4477 53.5027 46.4477 52.162C46.4477 50.3301 44.9808 48.8 43.1487 48.8C41.3169 48.8 39.8709 50.3301 39.8709 52.162C39.8709 53.5027 40.6219 54.6538 41.8502 55.1734V59.4689C40.7834 59.6163 39.7726 59.9391 38.8532 60.4235C36.916 58.9565 30.6059 54.4081 26.998 51.6637C27.0823 51.3548 27.1385 51.032 27.1385 50.6952C27.1385 48.6737 25.4891 47.0312 23.4605 47.0312C21.4391 47.0312 19.7966 48.6737 19.7966 50.6952C19.7966 52.7237 21.4391 54.3661 23.4605 54.3661C24.1484 54.3661 24.7871 54.1625 25.3416 53.8327L36.7966 61.8835ZM43.0786 73.3668C40.4044 73.3668 38.2355 71.1978 38.2355 68.5235C38.2355 65.8494 40.4044 63.6805 43.0786 63.6805C45.7528 63.6805 47.9217 65.8494 47.9217 68.5235C47.9218 71.1978 45.7529 73.3668 43.0786 73.3668Z" fill="#F78331"/>
<path d="M8 23C8 20.7909 9.79086 19 12 19H180C182.209 19 184 20.7909 184 23V37H8V23Z" fill="#555555" fill-opacity="0.3"/>
<circle cx="19" cy="28" r="4" fill="#E45353"/>
<circle cx="34" cy="28" r="4" fill="#F7CB31"/>
<circle cx="49" cy="28" r="4" fill="#9DF268"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M77.625 60.25C77.625 56.7292 80.4792 53.875 84 53.875C87.5208 53.875 90.375 56.7292 90.375 60.25C90.375 63.7708 87.5208 66.625 84 66.625C80.4792 66.625 77.625 63.7708 77.625 60.25ZM84 56.125C81.7218 56.125 79.875 57.9718 79.875 60.25C79.875 62.5282 81.7218 64.375 84 64.375C86.2782 64.375 88.125 62.5282 88.125 60.25C88.125 57.9718 86.2782 56.125 84 56.125Z" fill="#555555" fill-opacity="0.5"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M78 71.125C76.136 71.125 74.625 72.6361 74.625 74.5V76.2824C74.625 76.3096 74.6447 76.3327 74.6715 76.3371C80.8496 77.3458 87.1504 77.3458 93.3285 76.3371C93.3554 76.3327 93.375 76.3096 93.375 76.2824V74.5C93.375 72.6361 91.8639 71.125 90 71.125H89.4886C89.4492 71.125 89.4099 71.1313 89.3722 71.1434L88.074 71.5675C85.4268 72.4318 82.5732 72.4318 79.926 71.5675L78.6277 71.1434C78.5901 71.1313 78.5508 71.125 78.5113 71.125H78ZM72.375 74.5C72.375 71.3934 74.8934 68.875 78 68.875H78.5113C78.788 68.875 79.063 68.9188 79.3261 69.0046L80.6244 69.4286C82.8179 70.1447 85.1821 70.1447 87.3756 69.4286L88.6739 69.0046C88.937 68.9188 89.2119 68.875 89.4886 68.875H90C93.1066 68.875 95.625 71.3934 95.625 74.5V76.2824C95.625 77.4122 94.8061 78.3755 93.691 78.5576C87.2728 79.6055 80.7271 79.6055 74.3089 78.5576C73.1938 78.3755 72.375 77.4122 72.375 76.2824V74.5Z" fill="#555555" fill-opacity="0.5"/>
<rect x="106" y="59" width="44" height="3" rx="1" fill="#555555" fill-opacity="0.5"/>
<rect x="106" y="71" width="30" height="3" rx="1" fill="#555555" fill-opacity="0.5"/>
<rect x="29" y="91" width="20" height="3" rx="1" fill="#555555" fill-opacity="0.5"/>
<rect x="29" y="105" width="20" height="3" rx="1" fill="#555555" fill-opacity="0.5"/>
<rect x="72" y="91" width="76" height="3" rx="1" fill="#555555" fill-opacity="0.5"/>
<rect x="72" y="105" width="62" height="3" rx="1" fill="#555555" fill-opacity="0.5"/>
<rect x="72" y="119" width="76" height="3" rx="1" fill="#555555" fill-opacity="0.5"/>
<rect x="72" y="133" width="62" height="3" rx="1" fill="#555555" fill-opacity="0.5"/>
<rect x="72" y="147" width="76" height="3" rx="1" fill="#555555" fill-opacity="0.5"/>
<rect x="72" y="161" width="62" height="3" rx="1" fill="#555555" fill-opacity="0.5"/>
<rect x="29" y="119" width="20" height="3" rx="1" fill="#555555" fill-opacity="0.5"/>
<rect x="29" y="133" width="20" height="3" rx="1" fill="#555555" fill-opacity="0.5"/>
<rect x="29" y="147" width="20" height="3" rx="1" fill="#555555" fill-opacity="0.5"/>
<rect x="29" y="161" width="20" height="3" rx="1" fill="#555555" fill-opacity="0.5"/>
<circle cx="174" cy="167" r="26" fill="#00B47E"/>
<rect x="186.045" y="155" width="4" height="28" rx="2" transform="rotate(37.5 186.045 155)" fill="white"/>
<rect x="174.58" y="176.53" width="4" height="16.3303" rx="2" transform="rotate(128.5 174.58 176.53)" fill="white"/>
</svg>

);
export const TrestleIconSmall = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.01971 0C4.03679 0 0 4.03604 0 9.00901C0 13.982 4.03679 18.027 9.0107 18.027C13.9846 18.027 18.0214 13.991 18.0214 9.00901C18.0214 4.02703 13.9936 0 9.01971 0Z"
      fill="#44DAD3"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M38.9892 29.973C34.0153 29.973 29.9785 34.0091 29.9785 38.982C29.9785 43.955 34.0153 48 38.9892 48C43.9631 48 47.9999 43.964 47.9999 38.982C47.9999 34 43.9631 29.973 38.9892 29.973Z"
      fill="#44DAD3"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M48 0C34.7453 0 24.0045 10.7457 24.0045 23.9955H0V47.991C13.2547 47.991 24.0045 37.2453 24.0045 23.9955H48V0Z"
      fill="#0494B4"
    />
  </svg>
);

export const LeadAssessmentFileUploadIcon = (
  <svg
    width="200"
    height="200"
    viewBox="0 0 200 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M32 80C32 75.5817 35.5817 72 40 72H144C148.418 72 152 75.5817 152 80V168C152 172.418 148.418 176 144 176H40C35.5817 176 32 172.418 32 168V80Z"
      fill="#F5F5F5"
    />
    <path
      d="M33 80C33 76.134 36.134 73 40 73H144C147.866 73 151 76.134 151 80V168C151 171.866 147.866 175 144 175H40C36.134 175 33 171.866 33 168V80Z"
      stroke="#555555"
      stroke-opacity="0.1"
      stroke-width="2"
    />
    <path
      d="M82.4307 8.57438C84.6398 4.74804 89.5325 3.43704 93.3589 5.64618L162.641 45.6462C166.467 47.8553 167.778 52.748 165.569 56.5744L102.831 165.24C100.186 169.821 93.9019 170.62 90.1951 166.845L52.7228 128.688C51.2537 127.192 50.4307 125.179 50.4307 123.082L50.4307 66.1436C50.4307 64.7393 50.8003 63.3598 51.5025 62.1436L82.4307 8.57438Z"
      fill="white"
    />
    <path
      d="M83.2967 9.07438C85.2297 5.72633 89.5108 4.57921 92.8589 6.5122L162.141 46.5122C165.489 48.4452 166.636 52.7263 164.703 56.0744L101.965 164.74C99.6506 168.748 94.152 169.447 90.9086 166.144L53.4363 127.987C52.1509 126.678 51.4307 124.917 51.4307 123.082L51.4307 66.1436C51.4307 64.9148 51.7541 63.7077 52.3685 62.6436L83.2967 9.07438Z"
      stroke="#555555"
      stroke-opacity="0.1"
      stroke-width="2"
    />
    <path
      d="M62.2 13.7574C63.3435 9.48971 67.7302 6.95705 71.9979 8.10058L149.272 28.8061C153.54 29.9496 156.072 34.3363 154.929 38.6041L121.926 161.771C120.735 166.219 116.044 168.745 111.675 167.292L50.5294 146.962C47.2342 145.866 45.0221 142.77 45.0538 139.297L45.6263 76.5927C45.6324 75.9181 45.7239 75.2469 45.8985 74.5952L62.2 13.7574Z"
      fill="white"
    />
    <path
      d="M63.1659 14.0163C64.1665 10.282 68.0048 8.06591 71.7391 9.06651L149.013 29.772C152.747 30.7726 154.964 34.611 153.963 38.3452L120.96 161.512C119.918 165.404 115.813 167.614 111.99 166.343L50.8449 146.013C47.9616 145.054 46.026 142.345 46.0537 139.306L46.6262 76.6019C46.6316 76.0115 46.7117 75.4243 46.8644 74.854L63.1659 14.0163Z"
      stroke="#555555"
      stroke-opacity="0.1"
      stroke-width="2"
    />
    <path
      d="M44 24C44 19.5817 47.5817 16 52 16H132C136.418 16 140 19.5817 140 24V152C140 156.418 136.418 160 132 160H52C47.5817 160 44 156.418 44 152V24Z"
      fill="white"
    />
    <path
      d="M45 24C45 20.134 48.134 17 52 17H132C135.866 17 139 20.134 139 24V152C139 155.866 135.866 159 132 159H52C48.134 159 45 155.866 45 152V24Z"
      stroke="#555555"
      stroke-opacity="0.1"
      stroke-width="2"
    />
    <path
      d="M14.4851 105.94C13.2228 100.891 17.0416 96 22.2462 96H161.754C166.958 96 170.777 100.891 169.515 105.94L153.515 169.94C152.625 173.502 149.425 176 145.754 176H38.2462C34.5753 176 31.3754 173.502 30.4851 169.94L14.4851 105.94Z"
      fill="#F5F5F5"
    />
    <path
      d="M15.4552 105.698C14.3507 101.28 17.6922 97 22.2462 97H161.754C166.308 97 169.649 101.28 168.545 105.698L152.545 169.698C151.766 172.814 148.966 175 145.754 175H38.2462C35.0341 175 32.2343 172.814 31.4552 169.698L15.4552 105.698Z"
      stroke="#555555"
      stroke-opacity="0.1"
      stroke-width="2"
    />
    <circle cx="152" cy="174" r="26" fill="#00B47E" />
    <path
      d="M140.5 174.754L150.219 182.557L164.458 164.417"
      stroke="white"
      stroke-width="4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const ShareIcon = (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.125 2.75C12.125 1.36929 13.2443 0.25 14.625 0.25C16.0057 0.25 17.125 1.36929 17.125 2.75C17.125 4.13071 16.0057 5.25 14.625 5.25C13.9111 5.25 13.2676 4.95062 12.8126 4.47186L5.79494 8.37057C5.84721 8.57195 5.875 8.78296 5.875 9C5.875 9.21704 5.84721 9.42805 5.79494 9.62944L12.8126 13.5281C13.2676 13.0494 13.9111 12.75 14.625 12.75C16.0057 12.75 17.125 13.8693 17.125 15.25C17.125 16.6307 16.0057 17.75 14.625 17.75C13.2443 17.75 12.125 16.6307 12.125 15.25C12.125 15.033 12.1528 14.8219 12.2051 14.6206L5.1874 10.7219C4.73237 11.2006 4.08889 11.5 3.375 11.5C1.99429 11.5 0.875 10.3807 0.875 9C0.875 7.61929 1.99429 6.5 3.375 6.5C4.08889 6.5 4.73237 6.79938 5.1874 7.27814L12.2051 3.37943C12.1528 3.17805 12.125 2.96704 12.125 2.75Z"
      fill="white"
    />
  </svg>
);

export const DownloadIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.4697 9.21969C15.7626 8.92679 16.2374 8.92679 16.5303 9.21969C16.8232 9.51259 16.8232 9.98739 16.5303 10.2803L12.5303 14.2803C12.2374 14.5732 11.7626 14.5732 11.4697 14.2803L7.4697 10.2803C7.1768 9.98739 7.1768 9.51259 7.4697 9.21969C7.7626 8.92679 8.2374 8.92679 8.5303 9.21969L11.25 11.9393L11.25 3.24999C11.25 2.83578 11.5858 2.49999 12 2.49999C12.4142 2.49999 12.75 2.83578 12.75 3.24999L12.75 11.9393L15.4697 9.21969Z"
      fill="white"
    />
    <path
      d="M6 16V19H18V16"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const CircularGreenTickIcon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="10" cy="10" r="10" fill="#00B47E" />
    <path
      d="M5.71338 10.281L9.33554 13.1889L14.6419 6.42856"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const CircularDashIcon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="10" cy="10" r="10" fill="#555555" />
    <path
      d="M7.427 10.4286H12.427"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
    />
  </svg>
);

export const CircularCrossIcon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="10" cy="10" r="10" fill="#B23352" />
    <path
      d="M7.47388 12.5254L12.5246 7.47461"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
    />
    <path
      d="M7.47388 7.47461L12.5246 12.5253"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
    />
  </svg>
);

export const LinkedInIcon = (
  <svg
    width="16"
    height="15"
    viewBox="0 0 16 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.99984 14.6667H0.666504V4.66667H3.99984V14.6667ZM2.33384 3.33333C1.41184 3.33333 0.666504 2.586 0.666504 1.666C0.666504 0.746 1.41317 0 2.33384 0C3.2525 0 3.99984 0.747334 3.99984 1.666C3.99984 2.586 3.2525 3.33333 2.33384 3.33333ZM15.9998 14.6667H12.7952V9.8C12.7952 8.63933 12.7732 7.14667 11.1292 7.14667C9.4605 7.14667 9.20384 8.41067 9.20384 9.716V14.6667H5.99984V4.65933H9.07584V6.02667H9.11917C9.54717 5.24 10.5932 4.41067 12.1532 4.41067C15.3998 4.41067 15.9998 6.48333 15.9998 9.178V14.6667Z"
      fill="white"
    />
  </svg>
);

export const TickIcon = (
  <svg
    width="11"
    height="9"
    viewBox="0 0 11 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 4.76233L4.38069 7.47635L9.33333 1.16669"
      stroke="#00B47E"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const InQueueIcon = (
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="9.19995" cy="9.5" r="9" fill="#0494B4" />
    <path
      d="M9 4V9.5L12 12"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const HubSpotIcon = (
  <svg
    width="20"
    height="22"
    viewBox="0 0 20 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.478 9.13011C9.42088 10.1282 8.75975 11.5432 8.75975 13.1141C8.75975 14.3481 9.16822 15.4851 9.8547 16.3991L7.77003 18.4962C7.58473 18.4288 7.38678 18.391 7.18039 18.391C6.72561 18.391 6.30027 18.5679 5.98016 18.888C5.66009 19.208 5.48319 19.6375 5.48319 20.0925C5.48319 20.5472 5.66005 20.9726 5.98016 21.2927C6.30027 21.6127 6.72983 21.7812 7.18039 21.7812C7.63522 21.7812 8.06056 21.6169 8.38484 21.2927C8.70495 20.9726 8.88181 20.543 8.88181 20.0925C8.88181 19.9156 8.85659 19.7471 8.8018 19.5828L10.9076 17.4687C11.834 18.1804 12.9879 18.6016 14.2471 18.6016C17.2752 18.6016 19.722 16.1463 19.722 13.1183C19.722 10.3724 17.7132 8.0983 15.081 7.69822V5.10401C15.818 4.7923 16.2686 4.10164 16.2686 3.29722C16.2686 2.19805 15.3884 1.28 14.2892 1.28C13.1901 1.28 12.3225 2.19805 12.3225 3.29722C12.3225 4.10164 12.7731 4.7923 13.5101 5.10401V7.68134C12.87 7.7698 12.2636 7.96348 11.7119 8.25411C10.5496 7.37389 6.76353 4.64487 4.5988 2.9982C4.64933 2.81291 4.68308 2.61922 4.68308 2.41709C4.68308 1.2042 3.69341 0.21875 2.4763 0.21875C1.26341 0.21875 0.277954 1.2042 0.277954 2.41709C0.277954 3.6342 1.26341 4.61966 2.4763 4.61966C2.88903 4.61966 3.27224 4.4975 3.60495 4.29959L10.478 9.13011ZM14.2471 16.0201C12.6426 16.0201 11.3413 14.7187 11.3413 13.1141C11.3413 11.5096 12.6426 10.2083 14.2471 10.2083C15.8517 10.2083 17.153 11.5096 17.153 13.1141C17.153 14.7187 15.8517 16.0201 14.2471 16.0201Z"
      fill="currentColor"
    />
  </svg>
);
