import { AppBar, Box, Stack } from "@mui/material";
import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import TrestleLogo from "../../../assets/trestle_logo.svg";
import { Text14pxBold } from "../CommonComponents";
import TrestleButton from "../TrestleButton";
import { RouteConstants } from "../../../constants/RouteConstants";
import FooterComponent from "../Footer";
import { useAppSelector } from "../../../app/hooks";

const PublicPagesLayout = () => {
  const navigate = useNavigate();
  const isLoggedIn: any = useAppSelector(
    (state: any) => state.authReducer.idToken
  );

  return (
    <Stack height="100vh" className="overflow-hidden">
      <AppBar
        position="static"
        sx={{
          background: "#163545",
          fontFamily: "inherit",
          position: "relative",
          zIndex: "111111",
          boxShadow: "none",
        }}
        className="app-bar"
      >
        <div
          style={{
            padding: "35px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div className="max-w-[315px] w-full">
            <img
              src={TrestleLogo}
              alt="trestle logo"
              className="h-[24px] cursor-pointer"
            />
          </div>
          <div className="flex items-center justify-center gap-[8px]">
            {!isLoggedIn ? (
              <>
                <TrestleButton
                  onClick={() => navigate(RouteConstants.ROUTE_LOGIN)}
                  label="Log In"
                  type="transparent"
                  className="!h-[33px] !p-0 !min-w-[83px] !max-w-[83px]"
                />
                <TrestleButton
                  onClick={() => navigate(RouteConstants.ROUTE_SIGNUP)}
                  label="Sign Up"
                  type="primary"
                  className="!h-[33px] !p-0 !min-w-[83px] !max-w-[83px]"
                />
              </>
            ) : null}
          </div>
        </div>
      </AppBar>
      <Box className="overflow-auto flex flex-col justify-between h-full">
        <Outlet />
        <div></div>
        <FooterComponent />
      </Box>
    </Stack>
  );
};

export default PublicPagesLayout;
