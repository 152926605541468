import { Box, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import {
  ComponentLabelHeader,
  Text14pxMedium,
  Text16px,
  Text20pxBold,
} from "../Shared/CommonComponents";
import { LeadAssessmentHeaderText } from "../../constants/HtmlConstText";
import InputTextField from "../Shared/InputTextField";
import { AppConstants } from "../../constants/AppConstants";
import DragAndDropFileUploaderComp from "../Shared/DragAndDropFileUploader";
import {
  CircularCrossIcon,
  LeadAssessmentFileUploadIcon,
} from "../../assets/svgs";
import { uploadLeadAssessmentFile } from "./leadAssessmentServices";
import { validationEmail } from "../../utils/validations";
import TrestleLoaderButton from "../Shared/TrestleLoadingButton";
import { useAppDispatch } from "../../app/hooks";
import { setToast } from "../../app/toastReducer";
import { ConstantTexts } from "../../constants/ConstantTexts";
import { ActionButtonContainer } from "../Shared/PaymentWallet/WalletComponets/Styles";

const LeadAssessmentForm = () => {
  const dispatch = useAppDispatch();
  const [formData, setFormData] = useState<any>({ email: "" });
  const [formError, setFormError] = useState<any>({ email: "" });
  const [isOver, setIsOver] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const [fileUploadSuccess, setFileUploadSuccess] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const OnDataChange = (event: any) => {
    const { value, name } = event.target;

    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    loadRecaptchaScript();
  }, []);

  const loadRecaptchaScript = () => {
    if (!document.getElementById("recaptcha-script")) {
      const script = document.createElement("script");
      script.src = process.env.REACT_APP_GOOGLE_RECAPTCHA_URL || "";
      script.id = "recaptcha-script";
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);

      const style = document.createElement("style");
      style.innerHTML = `
      .grecaptcha-badge {
        bottom: 100px !important;
      }
    `;
      document.head.appendChild(style);
    }
  };

  const executeRecaptcha = (actionName: string): Promise<string> => {
    // Extend the Window interface for grecaptcha locally
    interface WindowWithRecaptcha extends Window {
      grecaptcha?: {
        ready: (callback: () => void) => void;
        execute: (
          siteKey: string,
          options: { action: string }
        ) => Promise<string>;
      };
    }

    const customWindow = window as WindowWithRecaptcha;

    return new Promise<string>((resolve, reject) => {
      if (customWindow.grecaptcha) {
        customWindow.grecaptcha.ready(() => {
          if (customWindow.grecaptcha) {
            customWindow.grecaptcha
              .execute(process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY || "", {
                action: actionName,
              })
              .then(resolve)
              .catch(reject);
          } else {
            reject("reCAPTCHA is not available after ready()");
          }
        });
      } else {
        reject("reCAPTCHA not loaded");
      }
    });
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    if (!handleValidation()) {
      setIsLoading(false);
      return;
    }
    const token = await executeRecaptcha("lead_assessment");
    const payload = new FormData();
    payload.append("email", formData.email);
    payload.append("file", files[0]);
    payload.append("recaptchaToken", token);

    await uploadLeadAssessmentFile(payload)
      .then((response) => {
        if (response.status === 200) {
          setFileUploadSuccess(true);
          setFormData({ email: "" });
          setFiles([]);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        const errorData = error.response?.data || {};
        dispatch(
          setToast({
            open: true,
            severity: AppConstants.TOAST_ERROR,
            msg: errorData.message,
          })
        );
        setFileUploadSuccess(false);
        setIsLoading(false);
      });
  };

  const handleValidation = () => {
    const { email = "" } = formData || {};
    let isError: boolean = true;

    let tempError = {
      email: validationEmail(email),
    };

    if (tempError.email) {
      isError = false;
    }

    setFormError({ ...tempError });
    return isError;
  };

  return (
    <Stack>
      <Stack
        sx={{ background: "#F5F5F5", padding: "32px 48px", width: "full" }}
        spacing={"12px"}
      >
        <ComponentLabelHeader>
          Real Contact Data Assessment
        </ComponentLabelHeader>
        <Text16px className="max-w-[1920px] text-justify">
          <LeadAssessmentHeaderText />
        </Text16px>
      </Stack>
      <Box sx={{ display: "flex", justifyContent: "center", py: "65px" }}>
        <Stack
          width={"600px"}
          spacing={3}
          sx={{ justifyContent: "center", alignItems: "center" }}
        >
          {!fileUploadSuccess ? (
            <>
              <InputTextField
                name={"email"}
                label={"Email"}
                value={formData}
                error={formError}
                type={"text"}
                onChange={OnDataChange}
                required={true}
                maxLength={AppConstants.MAX_EMAIL_LENGTH}
                className="!h-[48px]"
              />
              <Stack spacing={1} width={"100%"}>
                <Text14pxMedium>CSV File</Text14pxMedium>
                {files.length > 0 ? (
                  <Box className="flex justify-between items-center px-[20px] py-[10px] bg-[#F5F5F5]">
                    <Text16px>{files[0].name}</Text16px>
                    <Box
                      onClick={() => setFiles([])}
                      className="cursor-pointer"
                    >
                      {CircularCrossIcon}
                    </Box>
                  </Box>
                ) : (
                  <DragAndDropFileUploaderComp
                    setIsOver={setIsOver}
                    setFiles={setFiles}
                  />
                )}
              </Stack>
              <TrestleLoaderButton
                onClick={handleSubmit}
                loading={isLoading}
                disabled={formData.email === "" || files.length === 0}
                label="Submit"
                type="primary"
                className="!h-[50px] !p-0 !min-w-[270px] !max-w-[270px] !mt-[48px]"
              />
            </>
          ) : (
            <>
              {LeadAssessmentFileUploadIcon}
              <Text20pxBold c={"#163545"} className="text-center">
                Success! You will receive an email with a link to your data
                assessment in a few minutes.
              </Text20pxBold>
              <Text16px
                c={"#163545"}
                className="text-center flex justify-center gap-1"
              >
                A sample assessment similar to what you’ll receive can be viewed
                <ActionButtonContainer
                  onClick={() =>
                    window.open(
                      "https://portal.trestleiq.com/data-assessment/report/2af57ad4-e738-401b-894f-67814ae130af",
                      "_blank"
                    )
                  }
                  style={{ textDecoration: "underline" }}
                >
                  here.
                </ActionButtonContainer>
              </Text16px>
              <TrestleLoaderButton
                onClick={() => setFileUploadSuccess(false)}
                loading={isLoading}
                label="Upload Another File"
                type="primary"
                className="!h-[50px] !p-0 !min-w-[270px] !max-w-[270px] !mt-[48px]"
              />
            </>
          )}
        </Stack>
      </Box>
    </Stack>
  );
};

export default LeadAssessmentForm;
