import moment from "moment";
import * as _ from "lodash";

export const getUserName = (data: any) => {
  const { firstName = "-", lastName = "-" } = data;
  return `${firstName} ${lastName}`;
};

export const getUTCDate = (date: any) => {
  return new Date(new Date(date).toUTCString().replace("GMT", ""));
};

export const expiryDate = (date_string: string, creationDate: string) => {
  let expiration = moment(date_string).format("YYYY-MM-DD");
  let current_date = moment(new Date().toUTCString()).format("YYYY-MM-DD");
  let days: any = 0;
  if (expiration < current_date) {
    days = 0;
  } else {
    days = Number.isNaN(moment(expiration).diff(current_date, "days"))
      ? 0
      : moment(expiration).diff(current_date, "days");
  }
  return days;
};

export const fixJsonFormattingByKey = (commentsObj: any): void => {
  const jsonElement = document.querySelector(
    ".__json-pretty__"
  ) as HTMLElement | null;

  if (!jsonElement) {
    console.error('Element with class "__json-pretty__" not found.');
    return;
  }

  let jsonString = jsonElement.innerHTML;

  for (const [key, comment] of Object.entries(commentsObj)) {
    // Build a regex to match the key and its value
    const regex = new RegExp(
      `"<span class="__json-key__"[^>]*>${key}<\\/span>": (.+?),`,
      "g"
    );

    // Replace the value and append the comment
    jsonString = jsonString.replace(
      regex,
      `"<span class="__json-key__" style="color: #FFFFFF !important;;">${key}</span>": $1, <span style="color: #999 !important;;">${comment}</span>`
    );
  }

  jsonElement.innerHTML = jsonString;
};
