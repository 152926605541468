import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ConstantTexts } from "../../../constants/ConstantTexts";
import { Box, Grid2 } from "@mui/material";
import TrestleButton from "../../Shared/TrestleButton";
import { BatchUploadUpgradeIcon, HubspotContactVerificationIcon, HubspotIntegrationIcon } from "../../../assets/svgs";
import { Text16px, Text16pxBold } from "../../Shared/CommonComponents";
import { RouteConstants } from "../../../constants/RouteConstants";

const HubSpotHomePage = () => {
  const params = useSearchParams();
  const navigate = useNavigate();

  console.log("prams", params); // Output: 1234567890
  return (
    <Box>
      <Grid2 container spacing={4} height={"calc(100vh - 180px)"}>
        <Grid2
          size={6}
          alignItems={"flex-end"}
          justifyContent={"center"}
          height={"100%"}
          display={"flex"}
          flexDirection={"column"}
          gap={3}
          pr = {'100px'}
        >
          <Box className = "flex flex-col gap-[20px] items-center">
          <Box
            sx={{ width: "200px", height: "200px", cursor: "pointer" }}
            onClick={() => navigate(RouteConstants.ROUTE_HUB_SPOT_INTEGRATION)}
          >
            {HubspotIntegrationIcon}
          </Box>
          <Text16pxBold className="w-[300px] text-center !font-bold">
          HubSpot List Integration
          </Text16pxBold>
          <Text16px className="w-[300px]">
            To run HubSpot lists through Trestle’s Real Contact API and
            download/export the results back into HubSpot
          </Text16px>
          </Box>
        </Grid2>
        <Grid2
          size={6}
          alignItems={"flex-start"}
          justifyContent={"center"}
          height={"100%"}
          display={"flex"}
          flexDirection={"column"}
          gap={3}
          pl = {'100px'}
        >
          <Box className = "flex flex-col gap-[20px] items-center">
          <Box 
          sx={{ width: "200px", height: "200px", cursor: "pointer" }}
          onClick= {() => window.open('https://ecosystem.hubspot.com/marketplace/apps/trestle-real-contact-2019920', '_blank')}
          >
            {HubspotContactVerificationIcon}
            
          </Box>
          <Text16pxBold className="w-[300px] text-center !font-bold">
          Trestle HubSpot App
          </Text16pxBold>
          <Text16px className="w-[300px]">
            To validate/verify new contacts added or updated within HubSpot
            seamlessly via Trestle’s Real Contact app within HubSpot Marketplace
          </Text16px>
          </Box>
        </Grid2>
      </Grid2>
    </Box>
  );
};

export default HubSpotHomePage;
