import React, { useEffect, useState } from "react";
import TrestleTable, {
  TableHeaderProps,
} from "../../Shared/TrestleTable/TrestleTable";
import { ComponentLabelHeader } from "../../Shared/CommonComponents";
import LoaderComponent from "../../Shared/LoaderComponent";
import { HTTP_STATUS } from "../../../http/constants/http.status";
import { useDispatch } from "react-redux";
import { setToast } from "../../../app/toastReducer";
import { AppConstants } from "../../../constants/AppConstants";
import NoDataFound from "../../Shared/NoDataFound";
import moment from "moment";
import { getUTCDate } from "../../../utils/helpers";
import TrestleButton from "../../Shared/TrestleButton";
import { Box } from "@mui/material";
import {
  getAllBatchFilesAdmin,
  getBatchFiles,
} from "../../UserMode/BatchUpload/BatchUploadService";
import { handleMixPanel } from "../../../utils/mixpanelUtils";
import { MixPanelEvents } from "../../../constants/MixPanelEvents";
import { useAppSelector } from "../../../app/hooks";
import {
  CircularCrossIcon,
  CircularGreenTickIcon,
  InQueueIcon,
} from "../../../assets/svgs";
import ProgressBar from "../../Shared/ProgressBar/ProgressBar";
import OverflowTooltip from "../../Shared/OverFlowToolTip";
import DropDown from "../../Shared/DropDown";

export interface IBatchFilesResponse {
  inputFile: string;
  createdAt: Date;
  productName: string;
  apiKey: string;
  status: string;
  outputFile: string;
  completedAt: Date;
  userName: string;
  emailId: string;
}

const BatchUploadAdmin = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const [batches, setBatches] = useState<IBatchFilesResponse[]>([]);

  const [intervalId, setIntervalId] = useState<any>();

  const [pageNumber, setPageNumber] = useState(0);

  const [totalRecords, setTotalRecords] = useState(0);

  const [noOfRecords, setNoOfRecords] = useState(20);

const [batchTypeFilter, setBatchTypeFilter] = useState("ALL");

  const dispatch = useDispatch();

  const userData: any = useAppSelector(
    (state: any) => state.authReducer.userData
  );

  useEffect(() => {
    const processingBatches = batches.filter(
      (batch: any) =>
        batch.status === "PROCESSING" || batch.status === "IN_QUEUE"
    );
    if (processingBatches.length > 0) {
      if (intervalId != undefined) return;

      let interval = setInterval(async () => {
        if (!window.location.href.includes("/admin/batch")) {
          clearInterval(interval);
        }

        let reqData: any = { pageNo: pageNumber, pageSize: noOfRecords, batchType :  batchTypeFilter};
        const response = await getAllBatchFilesAdmin(reqData);
        const { status = 401, data = {} } = response || {};

        if (status === HTTP_STATUS.HTTP_OK)
          setBatches(data.data.batchExecutions);
      }, 5000);

      setIntervalId(interval);
    } else {
      clearInterval(intervalId);
      setIntervalId(undefined);
    }
  }, [batches]);

  useEffect(() => {
    handleMixPanel(MixPanelEvents.VISIT_ADMIN_BATCH_PAGE, {
      email: userData.email,
    });
  }, []);

  useEffect(() => {
    callGetBatchAPI();
  }, [pageNumber, noOfRecords, batchTypeFilter]);

  const callGetBatchAPI = async () => {
    let reqData: any = { pageNo: pageNumber, pageSize: noOfRecords, batchType :  batchTypeFilter};

    setLoading(true);
    const response = await getAllBatchFilesAdmin(reqData);
    setLoading(false);

    const { status = 401, data = {} } = response || {};

    if (status === HTTP_STATUS.HTTP_OK) {
      setBatches(data.data.batchExecutions);
      setTotalRecords(data.data.totalRecords);
    } else {
      const { details } = data;
      dispatch(
        setToast({
          open: true,
          severity: AppConstants.TOAST_ERROR,
          msg: details,
        })
      );
    }
  };

  const headCells: readonly TableHeaderProps[] = [
    {
      id: "id",
      numeric: false,
      disablePadding: false,
      label: "Id",
      width: "3%",
      isCustomElements: false,
      customElements: (ele: any) => {},
      sortable: true,
    },
    //   {
    //     id: "userName",
    //     numeric: false,
    //     disablePadding: false,
    //     label: "Name",
    //     width: "10%",
    //     isCustomElements: false,
    //     customElements: (ele: any) => {},
    //     sortable: true,
    //   },
    {
      id: "emailId",
      numeric: false,
      disablePadding: false,
      label: "Email",
      width: "13%",
      isCustomElements: false,
      customElements: (ele: any) => {},
      sortable: true,
    },
    {
      id: "inputFile",
      numeric: false,
      disablePadding: false,
      label: "Input File Name",
      width: "15%",
      isCustomElements: false,
      customElements: (ele: any) => {},
      sortable: true,
    },
    {
      id: "noOfRecords",
      numeric: false,
      disablePadding: false,
      label: "Records",
      width: "3%",
      isCustomElements: false,
      customElements: (ele: any) => {},
      sortable: true,
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Date Uploaded",
      width: "5%",
      isCustomElements: true,
      customElements: (ele: any) => {
        return (
          <OverflowTooltip
            text={moment(getUTCDate(ele.createdAt || "")).format(
              "MM/DD/YYYY HH:mm:ss"
            )}
          >
            <span>
              {moment(getUTCDate(ele.createdAt || "")).format(
                "MM/DD/YYYY HH:mm:ss"
              )}
            </span>
          </OverflowTooltip>
        );
      },
      sortable: false,
    },
    {
      id: "productName",
      numeric: false,
      disablePadding: false,
      label: "Product Name",
      width: "10%",
      isCustomElements: false,
      customElements: (ele: any) => {},
      sortable: false,
    },
    //   {
    //     id: "apiKey",
    //     numeric: false,
    //     disablePadding: false,
    //     label: "API Key",
    //     width: "10%",
    //     isCustomElements: true,
    //     customElements: (ele: any) => {
    //       return ele.apiKey.substring(0, 9) + "............";
    //     },
    //     sortable: true,
    //   },
    {
      id: "completedAt",
      numeric: false,
      disablePadding: false,
      label: "Completion Date",
      width: "9%",
      isCustomElements: true,
      customElements: (ele: any) => {
        return (
          <OverflowTooltip
            text={
              ele.completedAt
                ? moment(getUTCDate(ele.completedAt || "")).format(
                    "MM/DD/YYYY HH:mm:ss"
                  )
                : "-"
            }
          >
            <span>
              {ele.completedAt
                ? moment(getUTCDate(ele.completedAt || "")).format(
                    "MM/DD/YYYY HH:mm:ss"
                  )
                : "-"}
            </span>
          </OverflowTooltip>
        );
      },
      sortable: true,
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: "Status",
      width: "10%",
      isCustomElements: true,
      customElements: (ele: any) => {
        return getFileStatus(ele.status, ele.percentageCompleted);
      },
      sortable: true,
    },
    {
      id: "leadAssessment",
      numeric: false,
      disablePadding: false,
      label: "Lead Assessment",
      width: "5%",
      isCustomElements: true,
      customElements: (ele: any) => {
        return ele.assessmentUrl ? (
          <OverflowTooltip text={ele.assessmentUrl}>
            <a 
              href={ele.assessmentUrl} 
              target="_blank" 
              rel="noopener noreferrer"
              className="hover:opacity-80"
              style={{ color: "#0494B4" }}
            >
              View
            </a>
          </OverflowTooltip>
        ) : (
          "-"
        );
      },
      sortable: false,
    },
  ];

  const getFileStatus = (status: string, percentageCompleted: number) => {
    if (status === "FAILED") {
      return (
        <span className="flex gap-1" style={{ color: "#B23352" }}>
          {CircularCrossIcon} Failed
        </span>
      );
    } else if (status === "PROCESSING") {
      return <ProgressBar value={percentageCompleted} />;
    } else if (status === "COMPLETED" || status === "DELETED") {
      return (
        <span className="flex gap-1" style={{ color: "#00B47E" }}>
          {CircularGreenTickIcon} Completed
        </span>
      );
    } else if (status === "IN_QUEUE") {
      return (
        <span className="flex gap-1" style={{ color: "#0494B4" }}>
          {InQueueIcon} In queue
        </span>
      );
    } else return status;
  };

  return (
    <>
      <LoaderComponent loader={loading} />

      <div className="p-[50px] bg-[#F5F5F5]">
        <Box>
          <ComponentLabelHeader className="flex-1">
            Batch Upload
          </ComponentLabelHeader>
        </Box>
      </div>

      <div className="px-[50px]  h-fit bg-white">
      <div className="flex py-[20px] w-full justify-end !important">
      <DropDown
                name={"batchUpload"}
                label={"Batch Type"}
                value={"ALL"}
                error={""}
                placeholder={"Search"}
                onChange={(val : any) => {
                  setBatchTypeFilter(val);
                }}
                required={false}
                options={["ALL", "BATCH", "LEAD_ASSESSMENT", "HUBSPOT_BATCH"]}
                className="!h-[50px] text-[#163545] !w-[250px]"
                
      />
      </div>
        <div className="flex py-[20px] justify-between">
          {batches.length > 0 ? (
            <TrestleTable
              isPaginated={true}
              headCells={headCells}
              rows={batches}
              setPageNumber={setPageNumber}
              totalNoOfRecords={totalRecords}
              setNoOfRecords={setNoOfRecords}
            />
          ) : (
            !loading && <NoDataFound className="mt-52" />
          )}
        </div>
      </div>
    </>
  );
};

export default BatchUploadAdmin;
